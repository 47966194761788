<template lang="pug">
.sidebar
  .sidebar_link
    router-link(:to={name: 'Home'})
      img(src="~@/assets/images/logo.svg", alt="logo")

  .sidebar_logo
    router-link(:to={name: 'Home'})
      //- img(src="~@/assets/images/logo.svg", alt="logo")
    button(@click='stateSidebar')
      img(src="~@/assets/images/menu.svg", alt="menu")

  .sidebar_title
    img(src="~@/assets/images/book.svg", alt="library")
    .sidebar_title_text {{ $t('sidebar.library') }}

  .sidebar_browser
    button(v-for="category in finalCategories" :class="{active: category.category == userCurrentCategory}" @click="setCurrentCategory(category.category)") {{category.title}}

  .sidebar_bird_home
    img(src="~@/assets/images/bird.svg", alt="bird")

  .sidebar_terms_home {{ $t('sidebar.terms') }}
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'LibrarySide',
  computed: {
    ...mapState('app', ['categories']),
    ...mapState('user', ['userCategories', 'userCurrentCategory']),
    finalCategories() {
      let categories = [
        {
          title: 'Ver todo',
          category: null,
        },
      ]

      this.categories
        .filter((category) => category.name != 'Docentes')
        .forEach((category) => {
          if (Object.values(this.userCategories).includes(category.name)) {
            categories.push({
              title: category.name,
              category,
            })
          }
        })

      return categories
    },
  },
  methods: {
    ...mapMutations('user', ['setCurrentCategory']),
    stateSidebar() {
      this.$emit('stateSidebar')
    },
  },
}
</script>