<template lang='pug'>
.container_profile
  router-view
</template>

<script>
export default {
  name: 'Profile',
}
</script>
