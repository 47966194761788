<template lang='pug'>
.container_login
  .container_login_title.recover_title {{ $t("menu.recover.recover") }}

  .container_login_link(v-if='!status')
    .container_login_link_text {{ $t("menu.recover.fields") }}

  .container_login_link(v-else)
    .container_login_link_text {{ $t("menu.recover.success") }}

  validation-observer.container_login_form(v-if='!status', v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit)')
      validation-provider.container_login_form_container_provider(rules='required', name='contraseña', v-slot='{valid, dirty}', ref='contraseña')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(type='password', v-model='password', :placeholder='$t("menu.recover.input.a")')
          img(src='~@/assets/images/password.svg', alt='logo')

      validation-provider.container_login_form_container_provider(rules='required|confirmed:contraseña', name='confirmar contraseña', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(type='password', :class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(type='password', v-model='password_confirm', :placeholder='$t("menu.recover.input.b")')
          img(src='~@/assets/images/password.svg', alt='logo')
          img(src='~@/assets/images/password.svg', alt='logo')

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button(disable:, isLoading) {{ $t("menu.recover.recover") }}
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'Invalid',
  data() {
    return {
      password: null,
      password_confirm: null,
      status: false,
      token: null,
      id: null,
      username: null,
      app: null,
      isLoading: false,
    }
  },
  methods: {
    submit() {
      this.isLoading = true
      const url = `${process.env.VUE_APP_SERVER_URL}/apps/${this.id}/request_password_reset`

      let params = qs.stringify({
        'utf-8': '✓',
        token: this.token,
        username: this.username,
        new_password: this.password,
        confirm_new_password: this.password_confirm,
      })

      axios
        .post(url, params, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then((response) => {
          console.log(response)
          if (response.data.includes('successfully reset their password')) {
            this.status = true
          } else {
            this.$router.push('/token-invalido')
          }
        })
        .catch((error) => {
          console.log(error)
          this.$router.push('/token-invalido')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.password = '1234'
      this.password_confirm = '1234'
    }
  },
}
</script>