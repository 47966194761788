<template lang='pug'>
.container_sidebar(:class='{ sidebar_hide: !statusSide }')
  .container_sidebar_back(@click='changeClass')

  .container_sidebar_menu
    .container_sidebar_menu_buttons
      .container_sidebar_menu_buttons_button(v-if='currentSection !== "validateData"', @click='changeClass')
        .container_sidebar_menu_button_text {{ $t("menu.close") }}
        img(src='~@/assets/images/x-circle.svg', alt='close')

    LogIn(v-if='!user && currentSection === "login"', @logIn='performLogIn', @singUp='changePerform', @recover='recoverPerform', :isLoading='isLoading', :purchaseOption='purchaseOption')

    SignUp(v-if='!user && currentSection === "signup"', @logIn='changePerform', @singUp='performSignUp', :isLoading='isLoading')

    Recover(v-if='!user && currentSection === "recover"', @changePerform='changeRecover', :isLoading='isLoading')

    SchoolCode(v-if='user && currentSection === "schoolCode"', @redeem='performSchoolCode', @skip='skipRedeem')

    ValidateData(v-if='currentSection === "validateData"')

    WelcomeUser(v-if='currentSection === "welcomeUser"', @next='performFinish', :schoolCode='this.statusCode')

    //- Menu(v-if='user && currentSection !== "welcomeUser" && currentSection !== "validateData"', @logOut='performLogOut', @closeSide='changeClass')
    Menu(v-if='showMenu', @logOut='performLogOut', @closeSide='changeClass')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LogIn from '@/components/forms/LogIn'
import SignUp from '@/components/forms/SignUp'
import Recover from '@/components/forms/Recover'
import SchoolCode from '@/components/forms/SchoolCode'
import ValidateData from '@/components/forms/ValidateData'
import WelcomeUser from '@/components/forms/WelcomeUser'
import Menu from '@/components/forms/Menu'

export default {
  name: 'Sidebar',
  components: {
    LogIn,
    SignUp,
    Recover,
    SchoolCode,
    ValidateData,
    WelcomeUser,
    Menu,
  },
  props: ['sidebarShow', 'purchaseOption'],
  computed: {
    ...mapState('user', ['user']),
    showMenu() {
      return this.user && !['validateData', 'schoolCode', 'welcomeUser'].includes(this.currentSection)
    }
  },
  methods: {
    ...mapActions('user', ['logIn', 'signUp', 'logOut', 'redeemSchoolCode']),
    changeClass() {
      this.statusSide = false
      setTimeout(() => {
        this.$emit('stateSidebar')
      }, 250)
    },
    changePerform() {
      if (this.currentSection === 'login') {
        this.currentSection = 'signup'
      } else {
        this.currentSection = 'login'
      }
    },
    recoverPerform() {
      this.currentSection = 'recover'
    },
    changeRecover(option) {
      this.currentSection = option
    },
    currentRoute() {
      let path = this.$router.currentRoute.fullPath

      if (path.includes('/tienda')) {
        return 'store'
      } else {
        return 'home'
      }
    },
    async performLogIn({ username, password }) {
      this.isLoading = true

      try {
        await this.logIn({ username, password })
      } catch (error) {
        //101 141
        console.log("🚀 ~ file: Sidebar.vue ~ line 92 ~ performLogIn ~ error", error, error.code)

        this.$eventBus.$emit('alert', {
          type: 'danger',
          message: error.code == 101 ? 'Usuario y/o contraseña inválida' : error.message,
          error,
        })
      }

      this.isLoading = false
    },
    async performSignUp(data) {
      this.isLoading = true
      const isTeacher = data.role === 'Maestro'

      try {
        await this.signUp({
          username: data.mail,
          password: data.password,
          name: data.username,
          phone: data.phone,
          institute: !isTeacher ? data.school : undefined,
          grade: isTeacher ? data.grade : undefined,
          role: isTeacher ? 'teacher' : 'user'
        })

        this.currentSection = 'schoolCode'
      } catch (error) {
        this.$eventBus.$emit('alert', {
          type: 'warning',
          message: 'Ya existe una cuenta con éste correo electrónico',
          error
        })
      }

      this.isLoading = false
    },
    async performSchoolCode({ schoolCode }) {
      this.currentSection = 'validateData'
      try {
        await this.redeemSchoolCode(schoolCode)
        this.currentSection = 'welcomeUser'

      } catch (error) {
        this.$eventBus.$emit('alert', {
          type: 'warning',
          message: 'Clave escolar inválida, comprueba tu clave escolar e intenta de nuevo'
        })

        this.currentSection = 'schoolCode'
      }
    },
    skipRedeem() {
      this.currentSection = 'welcomeUser'
    },
    performFinish() {
      this.currentSection = 'login'
    },
    performLogOut() {
      let path = this.$router.currentRoute.fullPath
      this.logOut()

      if (path.includes('/perfil') || path.includes('/mis-libros')) {
        this.$router.push({ name: 'Home' })
      }
    },
  },
  watch: {
    user() {
      if (this.currentRoute() === 'store') {
        this.$emit('purchase')
      }
    }
  },
  data() {
    return {
      currentSection: 'login',
      isLoading: false,
      userData: null,
      statusSide: false,
      statusCode: null,
    }
  },
  mounted() {
    this.statusSide = this.sidebarShow
  }
}
</script>