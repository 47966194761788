<template lang='pug'>
.container_profile_billing
  .container_profile_billing_title {{ $t("profile.billing.title") }}

  .container_profile_billing_loading(v-if='isLoading')
    .loading

  .container_profile_billing_cards(v-else-if='userCards.length > 0')
    .container_profile_billing_cards_card(v-for='card in userCards', :key='card.id')
      .container_profile_billing_cards_card_container
        .container_profile_billing_cards_card_container_image
          img.container_profile_billing_cards_card_container_image_img(v-if='card.brand === "visa"', src='~@/assets/images/visa.png', alt='visa')
          img.container_profile_billing_cards_card_container_image_img(v-if='card.brand === "mastercard"', src='~@/assets/images/mastercard.png', alt='mastercard')
          img.container_profile_billing_cards_card_container_image_img(v-if='card.brand === "american_express"', src='~@/assets/images/amex.png', alt='americanExpress')

        .container_profile_billing_cards_card_container_info
          .container_profile_billing_cards_card_container_info_number **** {{ card.lastFour }}
          .container_profile_billing_cards_card_container_info_type {{ card.brand.split("_").join(" ") }}

        button.container_profile_billing_cards_card_delete(@click='alertCard({ id: card.id, number: card.lastFour })') {{ $t("profile.billing.delete_card") }}

  .container_profile_billing_cards(v-else)
    .result_billing {{ $t("profile.billing.no_cards") }}

  .container_profile_billing_alert(v-if='showAlert')
    .container_profile_billing_alert_container
      .container_profile_billing_alert_container_text {{ $t("profile.billing.message_alert") }} **** {{ this.numberCard }} ?
      .container_profile_billing_alert_container_button(@click='deleteCard(true)') {{ $t("profile.billing.accept") }}
      .container_profile_billing_alert_container_button(@click='deleteCard(false)') {{ $t("profile.billing.deny") }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Billing',
  computed: {
    ...mapState('user', ['userCards']),
  },
  methods: {
    ...mapActions('user', ['fetchUserCards', 'deleteUserCard']),
    alertCard(card) {
      this.cardId = card.id
      this.numberCard = card.number
      this.showAlert = true
    },
    async deleteCard(option) {
      if (option) {
        let response = await this.deleteUserCard(this.cardId)

        if (!response.status) {
          this.$eventBus.$emit('alert', {
            type: 'danger',
            message: 'Ocurrio un error al eliminar tu tarjeta',
          })
        } else {
          this.$eventBus.$emit('alert', {
            type: 'info',
            message: 'Tarjeta eliminada',
          })
        }
      }

      this.showAlert = false
    },
  },
  watch: {
    userCards() {
      this.isLoading = false
    },
  },
  data() {
    return {
      showAlert: false,
      cardId: null,
      numberCard: null,
      isLoading: false,
    }
  },
  mounted() {
    if (this.userCards.length === 0) {
      this.isLoading = true
      this.fetchUserCards()
    }
  },
}
</script>