import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Library from '@/views/Library.vue'
import Profile from '@/views/Profile.vue'
import Store from '@/views/Store.vue'
import Information from '@/components/Information.vue'
import Billing from '@/components/Billing'
import Privacy from '@/components/Privacy'
import PrivacyHome from '@/views/Privacy'
import store from '@/store'
import PdfViewer from '@/views/PdfViewer'
import Recover from '@/views/Recover'
import Invalid from '@/views/Invalid'
import NotFound from '@/views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mis-libros',
    name: 'Library',
    component: Library,
    meta: { requieresAuth: true }
  },
  {
    path: '/perfil',
    name: 'Profile',
    meta: { requieresAuth: true },
    component: Profile,
    children: [
      {
        path: 'informacion',
        name: 'Information',
        component: Information
      },
      {
        path: 'pagos',
        name: 'Billing',
        component: Billing
      },
      {
        path: 'aviso-de-privacidad',
        name: 'Privacy',
        component: Privacy
      }
    ]
  },
  {
    path: '/tienda/:bookId',
    name: 'Store',
    component: Store
  },
  {
    path: '/pdf/:bookId',
    name: 'PDF',
    component: PdfViewer,
    meta: { requieresAuth: true }
  },
  {
    path: '/aviso-de-privacidad',
    name: 'PrivacyHome',
    component: PrivacyHome
  },
  {
    path: '/recuperar-cuenta',
    name: 'Recover',
    component: Recover
  },
  {
    path: '/token-invalido',
    name: 'Invalid',
    component: Invalid
  },
  {
    path: '*',
    name: 'Not-Found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requieresAuth)) {
    if (store.state.user.user) {
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
