<template lang="pug">
.sidebar
  .sidebar_link
    router-link(:to={name: 'Home'})
      img(src="~@/assets/images/logo.svg", alt="logo")

  .sidebar_logo
    router-link(:to={name: 'Home'})
      //- img(src="~@/assets/images/logo.svg", alt="logo")
    button(@click='stateSidebar')
      img(src="~@/assets/images/menu.svg", alt="menu")

  .sidebar_title
    img(src="~@/assets/images/store.svg", alt="store")
    .sidebar_title_text {{ $t('sidebar.store') }}

  .sidebar_bird
    img(src="~@/assets/images/bird.svg", alt="bird")

  .sidebar_terms {{ $t('sidebar.terms') }}
</template>

<script>
export default {
  name: 'StoreSide',
  methods: {
    stateSidebar() {
      this.$emit('stateSidebar')
    },
  },
}
</script>