<template lang='pug'>
.container_login
  .container_login_title(v-if='currentRoute() === "store" && purchaseOption') {{ $t("menu.login.purchase") }}
  .container_login_title(v-else) {{ $t("menu.login.title") }}

  validation-observer.container_login_form(v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit)')
      validation-provider.container_login_form_container_provider(rules='required|email', name='correo', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='username', :placeholder='$t("menu.login.input.user")')
          img(src='~@/assets/images/mail.svg', alt='mail')

      validation-provider.container_login_form_container_provider(rules='required', name='password', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(type='password', v-model='password', :placeholder='$t("menu.login.input.password")')
          img(src='~@/assets/images/password.svg', alt='password')

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button(:disabled='isLoading') {{ isLoading ? $t("menu.login.input.loading") : $t("menu.login.input.next") }}

  .container_login_link
    .container_login_link_text {{ $t("menu.login.account") }} #[a.container_login_link_bold(@click='singUp') {{ $t("menu.login.registry") }}]
    .container_login_link_text #[a.container_login_link_bold(@click='recover') {{ $t("menu.login.recover") }}]

  .container_login_download
    .container_login_download_text {{ $t("menu.login.download") }}
    img.container_login_download_image(src='~@/assets/images/download_google.svg', alt='google', @click='redirect(1)')
    img.container_login_download_image(src='~@/assets/images/download_apple.svg', alt='apple', @click='redirect(2)')
</template>

<script>
export default {
  name: 'LogIn',
  props: ['isLoading', 'purchaseOption'],
  methods: {
    submit() {
      this.$emit('logIn', this.$data)
    },
    singUp() {
      this.$emit('singUp')
    },
    recover() {
      this.$emit('recover')
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
    currentRoute() {
      let path = this.$router.currentRoute.fullPath

      if (path.includes('/tienda')) {
        return 'store'
      } else {
        return 'home'
      }
    },
    redirect(option) {
      if (option === 1) {
        window.open('https://play.google.com/store/apps/details?id=app.lucerolozano.mobile')
      } else {
        window.open('https://apps.apple.com/us/app/lucero-lan/id1540367657')
      }
    },
  },
  data() {
    return {
      username: null,
      password: null,
      errors: '',
    }
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.username = 'luis@wearetorque.com'
      this.password = '1234'
    }
  },
  watch: {
    username(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.username = newValue.toLowerCase()
      }
    },
  },
}
</script>