import Parse from 'parse'
import './models/User'
import './models/Institute'
import './models/Book'
import './models/Card'

Parse.initialize(process.env.VUE_APP_ID_APP, process.env.VUE_APP_KEY_JS)
Parse.serverURL = process.env.VUE_APP_SERVER_URL

export default Parse
