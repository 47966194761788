<template lang="pug">
SchoolCodeMenu(v-if='currentSection === "code"', @returnMenu='menu', @next='next')
ValidateData(v-else-if='currentSection === "validate"')

.container_menu(v-else)
  .container_menu_options
    router-link.container_menu_options_option(:to='{ name: "Library" }', @click.native='closeSide')
      .container_menu_options_option_text {{ $t("menu.user.books") }}
      img.container_menu_options_option_image(src='~@/assets/images/book.svg', alt='book')

    router-link.container_menu_options_option(:to='{ name: "Information" }', @click.native='closeSide')
      .container_menu_options_option_text {{ $t("menu.user.profile") }}
      img.container_menu_options_option_image(src='~@/assets/images/user-circle.svg', alt='user')

    router-link.container_menu_options_option(:to='{ name: "Home" }', @click.native='closeSide')
      .container_menu_options_option_text {{ $t("menu.user.store") }}
      img.container_menu_options_option_image(src='~@/assets/images/store.svg', alt='store')

    button.container_menu_options_option(@click='code')
      .container_menu_options_option_text {{ $t("menu.user.unlock") }}
      img.container_menu_options_option_image(src='~@/assets/images/unlock.svg', alt='unlock')

  .container_menu_buttons
    img.container_menu_buttons_image(src='~@/assets/images/download_apple.svg', alt='apple', @click='redirect(2)')
    img.container_menu_buttons_image(src='~@/assets/images/download_google.svg', alt='google', @click='redirect(1)')

    a.container_menu_buttons_button(href='mailto:lcastr60@gmail.com')
      .container_menu_buttons_button_text {{ $t("menu.user.contact") }}
      img(src='~@/assets/images/mail.svg', alt='contact')

    button.container_menu_buttons_button(@click='logOut')
      .container_menu_buttons_button_text {{ $t("menu.user.logout") }}
      img(src='~@/assets/images/logout.svg', alt='logout')
</template>

<script>
import SchoolCodeMenu from '@/components/forms/SchoolCodeMenu'
import ValidateData from '@/components/forms/ValidateData'
import { mapActions } from 'vuex'

export default {
  name: 'Menu',
  components: {
    SchoolCodeMenu,
    ValidateData,
  },
  data() {
    return {
      currentSection: 'menu',
    }
  },
  methods: {
    ...mapActions('user', ['redeemSchoolCode']),
    logOut() {
      this.$emit('logOut')
    },
    closeSide() {
      this.$emit('closeSide')
    },
    code() {
      this.currentSection = 'code'
    },
    menu() {
      this.currentSection = 'menu'
    },
    async next({ schoolCode }) {
      this.currentSection = 'validate'

      try {
        await this.redeemSchoolCode(schoolCode)
        this.$eventBus.$emit('alert', {
          type: 'info',
          message: "Tus libros estan disponibles en la seccion 'Mis libros'",
        })

        this.currentSection = 'menu'
      } catch (error) {
        this.$eventBus.$emit('alert', {
          type: 'warning',
          message: 'Clave escolar inválida, comprueba tu clave escolar e intenta de nuevo',
        })

        this.currentSection = 'code'
      }
    },
    redirect(option) {
      if (option === 1) {
        window.open('https://play.google.com/store/apps/details?id=app.lucerolozano.mobile')
      } else {
        window.open('https://apps.apple.com/us/app/lucero-lan/id1540367657')
      }
    },
  },
}
</script>