import Parse from 'parse'

export default class Category extends Parse.Object {
  constructor() {
    super('Category')

    this.books = []
  }

  get name() {
    return this.get('name')
  }

  static async loadEnabled() {
    let categories = await new Parse.Query(this)
      .equalTo('enabled', true)
      .ascending('order')
      .find()

    categories.forEach(async category => {
      category.books = await category
        .relation('books')
        .query()
        .equalTo('enabled', true)
        .ascending('title')
        .find()
    })

    return categories
  }

  static getById(id) {
    return new Parse.Query(this).get(id)
  }
}

Parse.Object.registerSubclass('Category', Category)
