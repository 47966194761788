<template lang='pug'>
.container_profile_privacyAndTerms
  .container_profile_privacyAndTerms_title {{ $t("profile.privacy.title") }}

  .container_profile_privacyAndTerms_subtitle Introducción
  .container_profile_privacyAndTerms_text.
    Nueva Editorial Lucero, S.A. de C.V. es una empresa cien por ciento mexicana dedicada a la edición, elaboración y venta de libros de texto escolares y material didáctico, tales como libros, DVD's, láminas educativas, entre otros, (la “<u>Sociedad</u>”) con domicilio en Crestón 312-2 Col. Jardines del Pedregal 01900 Álvaro Obregón, CDMX, e identificada con RFC NEL070704UJ9, teléfono 24575951 y correo electrónico <strong>lcastr60@gmail.com</strong>, es una entidad comprometida con la protección de la privacidad y de toda información que pueda asociarse o relacionarse con personas físicas y jurídicas, determinadas o determinables (los “Datos Personales”), a la cual tenga acceso en el desarrollo de sus actividades y funciones. La Sociedad recopila, utiliza, almacena, transfiere, procesa y protege sus Datos Personales tales como los de identificación, contacto, financieros, laborales, entre otros, información que podrá ser obtenida en el curso y para el desarrollo de sus actividades y funciones.
    <br/><br/>
    Sus Datos Personales son muy importantes para nosotros y serán tratados con base en los principios que establece la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante “<u>Ley de Datos Personales</u>”) y se utilizarán solamente para las finalidades señaladas a continuación en el presente Aviso de Privacidad.
    <br/><br/>
    El presente documento (el “<u>Aviso de Privacidad</u>”) tiene como finalidad informarle el tipo de datos personales que recabamos de usted, las finalidades de su tratamiento, las transferencias que podemos efectuar y las opciones que tiene para ejercer los derechos de acceso, rectificación, cancelación y oposición previstos en la Ley de Datos Personales.

  .container_profile_privacyAndTerms_subtitle Ámbito de Aplicación
  .container_profile_privacyAndTerms_text.
    El presente Aviso de Privacidad de la Sociedad está dirigido a: usuarios y potenciales usuarios que utilicen la página web <strong>www.lucerolozano.com.mx</strong>, y la Aplicación de la Sociedad (la “<u>APP</u>”) y que de cualquier manera estén relacionados o involucrados en un trámite con la Sociedad, ya sea a través de la página web, la APP, en forma presencial, telefónica o escrita, a sus trabajadores, candidatos y retirados, proveedores y en general, a cualquier persona cuyos Datos Personales estén siendo o vayan a ser tratados por la Sociedad, incluso, mediante herramientas como cookies, web-beacons y otras tecnologías análogas (indistintamente, “<u>Titular</u>” o “<u>Cliente</u>” o “<u>Usted</u>”). 
    <br/><br/>
    Este Aviso de Privacidad se aplicará a todo tratamiento realizado dentro del territorio de México por la Sociedad y, en lo que corresponda, con aquellos terceros con los que la Sociedad acuerde todo o parte de la realización de cualquier actividad relacionada con el tratamiento de Datos Personales respecto de los cuales la Sociedad actúa como responsable de los Datos Personales.

  .container_profile_privacyAndTerms_subtitle Responsable directo del tratamiento de sus Datos Personales
  .container_profile_privacyAndTerms_text.
    La persona encargada de la responsabilidad de protección de datos es la Sociedad, quien será el responsable y quien decidirá sobre el tratamiento que se le dé a sus datos personales. Si Usted desea contactarlo, puede hacerlo en el correo <strong>lcastr60@gmail.com</strong> 
    <br/><br/>
    Para la Sociedad es muy importante el respeto a la privacidad de los datos de nuestros Clientes y mantener a salvo dicha información. Tenemos un compromiso con la confidencialidad de sus datos y haremos lo posible por mantener a salvo dicha información.

  .container_profile_privacyAndTerms_subtitle Revocación
  .container_profile_privacyAndTerms_text.
    En caso de que un Cliente o el Titular desee revocar su consentimiento respecto al tratamiento de sus datos, favor de enviarnos un correo electrónico a <strong>lcastr60@gmail.com</strong>

  .container_profile_privacyAndTerms_subtitle Alcance
  .container_profile_privacyAndTerms_text.
    Este Aviso de Privacidad se aplica tanto a nuestra página web como a nuestra APP. que es donde se recolecta la información de nuestros Clientes. Dentro de la información que podemos recolectar está su nombre, su domicilio (para efectos del envío) y otros. Asimismo, informamos que utilizamos cookies para facilitar la comunicación con nuestro sitio web o APP.
    <br/><br/>
    La Sociedad hace el compromiso de asegurar que toda la información que recolecte a través de sus ventas en línea será resguardada adecuadamente.
    <br/><br/>
    Le recordamos que tiene usted la opción de no recibir correos electrónicos, información por correo postal y/o llamadas de teléfono, así como actualizar y corregir su información personal.
    <br/><br/>
    Para la Sociedad, la responsabilidad en el tratamiento de sus datos es muy importante, por lo que en todo momento salvaguardaremos esta información personal, cumpliendo con todas las Leyes de nuestro país.

  .container_profile_privacyAndTerms_subtitle Política de Privacidad
  .container_profile_privacyAndTerms_text.
    La Sociedad es una empresa sin sucursales que se compromete a salva guardar la información confidencial que reciba de cada uno de sus Clientes.
    <br/><br/>
    Esta política de privacidad describe las prácticas sobre el tratamiento de la información y la privacidad para la Sociedad, su página web y su APP. Cuando usted nos entrega su información personal, en ese momento está Usted aceptando las prácticas sobre el tratamiento de la información que se describen en este Aviso de Privacidad.

  .container_profile_privacyAndTerms_subtitle Datos Personales
  .container_profile_privacyAndTerms_text.
    La Sociedad recoge información ya sea desde su página web como de su APP. Toda la información que recabemos está sujeta a las normas de privacidad y confidencialidad previstas en el presente Aviso de Privacidad. Ya sea los datos generales de un Cliente, envío de información electrónica con ofertas, entre otros.

  .container_profile_privacyAndTerms_subtitle Contacto
  .container_profile_privacyAndTerms_text.
    A través de nuestro formulario, nuestros Clientes podrán enviar mensajes dirigidos a nuestras áreas de operación y administración. Los datos que se recaban son:
    <br/><br/>

  ul
    li Nombre
    li Correo electrónico
    li Número telefónico
    li Dirección de envío
    li Nivel escolar
    li Ocupación

  .container_profile_privacyAndTerms_text.
    Podrán tratarse otros datos personales de identificación y/o contacto, no sensibles, que no se incluyan en la lista anterior siempre y cuando dichos datos se consideren de la misma naturaleza y se utilicen para las finalidades señaladas en el presente Aviso de Privacidad. La Sociedad no recabará datos personales sensibles relacionados con su afiliación política y religiosa.  
    <br/><br/>
    [También podremos recabar los siguientes datos personales, siempre con su consentimiento: su número de tarjeta; datos necesarios para emitir recibos y/o facturas, como lo son su registro federal de contribuyentes (RFC)]


  .container_profile_privacyAndTerms_subtitle ¿Cómo obtenemos sus datos?
  .container_profile_privacyAndTerms_text.
    Los datos personales se pueden recolectar de la siguiente manera:
    <br/><br/>
    DIRECTAMENTE. A través de nuestro sitio web o de la APP cuando los usuarios nos proporcionan Datos Personales por medio de correo electrónico, teléfono, servicios digitales de mensajería, o a través de capturar sus datos en nuestro sitio web o en nuestros formularios digitales de la APP.

  .container_profile_privacyAndTerms_subtitle Registro a nuestro portal
  .container_profile_privacyAndTerms_text.
    A través de nuestra APP o de nuestro sitio Web de internet <strong>www.lucerolozano.com.mx</strong>, el Cliente tendrá la opción de hacer compras en línea. Para ello, requeriremos un correo electrónico y una contraseña, además de nombre completo, domicilio adonde se enviarán físicamente los productos que se adquieran (en caso de que no sean digitales), además de requerirlo para facturarle, y datos de su tarjeta de crédito o débito donde se cargarán las compras. Todos estos datos son cuidadosamente guardados y no se comparten con ninguna persona física o moral.

  .container_profile_privacyAndTerms_subtitle Notificación de ofertas
  .container_profile_privacyAndTerms_text.
    Existirán ocasiones en las que enviemos a su correo determinadas ofertas o promociones especiales; por este conducto, garantizamos que solo la Sociedad tiene acceso a sus datos. Usted puede solicitar no recibir dicha información enviando un correo a <strong>lcastr60@gmail.com</strong>

  .container_profile_privacyAndTerms_subtitle Uso y divulgación de la Información
  .container_profile_privacyAndTerms_text.
    Cuando la Sociedad le informe de información de nuevos productos, promociones u ofertas, a través de correo electrónico, confirmamos que únicamente nosotros tenemos la información recabada, y solo le serán enviados a nuestros Clientes previamente registrados.

  .container_profile_privacyAndTerms_subtitle Transferencias de datos con terceros
  .container_profile_privacyAndTerms_text.
    Como parte de las operaciones propias de La Sociedad y con la finalidad de cumplir con las acciones descritas, la Sociedad no hace transferencias de información más que con la empresa de hosting que administra el lugar, con el objetivo de darle mantenimiento.  
    <br/><br/>
    Para tal efecto, la Sociedad se asegurará a través de la firma de convenios y/o la adopción de otros documentos vinculantes, que dicho tercero mantenga medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus datos personales, así como que dichos terceros únicamente utilicen sus datos personales para las finalidades establecidas de conformidad con el presente Aviso de Privacidad.
    <br/><br/>
    La Sociedad no cederá, venderá o transferirá sus Datos Personales, a terceros no relacionados, sin su consentimiento previo. Únicamente, podrá transferir sus datos personales en los casos previstos en el presente Aviso de Privacidad y en la Ley de Datos Personales.
    <br/><br/>
    Cuando se transfieran los Datos Personales a terceros nacionales o extranjeros, se comunicará a éstos el aviso de privacidad y las finalidades del tratamiento. El tercero receptor, asumirá las mismas obligaciones que correspondan al responsable que transfirió los datos.
    <br/><br/>
    La Sociedad podrá transferir sus datos personales en los siguientes supuestos establecidos en la Ley de Datos Personales para lo cual no requerimos su consentimiento:
    <br/><br/>
    Cuando la transferencia: (a) sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común de La Sociedad, o a una sociedad matriz o a cualquier sociedad del mismo grupo de La Sociedad que opere bajo los mismos procesos y políticas internas; (b) esté prevista en una ley o Tratado en los que México sea parte; (c) sea necesaria por virtud de un contrato celebrado o por celebrar en su interés, por La Sociedad y un tercero; (e) sea legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia; (f) para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; y/o (g) sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre La Sociedad y el titular.

  .container_profile_privacyAndTerms_subtitle Información Confidencial y Medidas de Seguridad
  .container_profile_privacyAndTerms_text.
    Cuando se encuentre en nuestro sitio, tenga la total seguridad de que no se compartirá su información con terceros, a menos que se requiera por orden judicial. La información de sus datos no se vende ni renta a terceras personas.
    <br/><br/>
    Asimismo, la información que cada Cliente proporciona está protegida por datos que el usuario proporcionó y que solo él conoce.
    <br/><br/>
    La confidencialidad y protección de los Datos Personales es una prioridad para la Sociedad, por lo que la empresa ha establecido y conservado medidas de seguridad administrativas, técnicas y físicas para evitar el daño, pérdida, alteración, destrucción, así como el uso, acceso o divulgación indebida. Adicionalmente, la Sociedad ha adoptado y adoptará las medidas técnicas, tecnológicas, humanas y administrativas que sean del caso para otorgar seguridad a los registros salvaguardando su confidencialidad, integridad y disponibilidad. 
    <br/><br/>
    La Sociedad está comprometido con combatir los riesgos de fraude y suplantación y por lo tanto lleva adelante rigorosas validaciones de identidad de los Clientes en concordancia con los principios. 
    <br/><br/>
    La Sociedad, implementará las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.
    <br/><br/>
    Únicamente, el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá participar en el tratamiento de sus datos personales. El personal autorizado tiene prohibido permitir el acceso a personas no autorizadas y utilizar sus datos personales para fines distintos a los establecidos en el presente Aviso de Privacidad. La obligación de confidencialidad de las personas que participan en el tratamiento de sus datos personales subsiste aun después de terminada la relación con la Sociedad.

  .container_profile_privacyAndTerms_subtitle Finalidades del tratamiento de los datos personales:
  .container_profile_privacyAndTerms_text.
    La Sociedad recaba, utiliza, almacena y transfiere sus Datos Personales, en la medida en que las leyes aplicables lo permiten, para llevar a cabo lo siguiente:

  ul
    li Para brindar nuestros servicios de venta de libros de texto escolares y material didáctico, tales como libros, DVD's, láminas educativas, entre otros.
    li Comprobación ante patrocinadores y donantes de los beneficiarios que reciben los servicios de la Sociedad.
    li Desarrollo de nuevos servicios: asociados con la venta de libros de texto escolares y material didáctico, tales como libros, DVD's, láminas educativas, entre otros.
    li Crear una base de datos que nos permita identificar y categorizar a los usuarios.
    li Seguimiento a los servicios otorgados derivados de o relacionados con la venta de libros de texto escolares y material didáctico, tales como libros, DVD's, láminas educativas, entre otros.

  .container_profile_privacyAndTerms_subtitle Sus datos se pudieran usar también para los siguientes fines secundarios:
  ul
    li Para utilizar su información con fines estadísticos de la organización.
    li Estudios para determinar la satisfacción de los Clientes.
    li Envío de información sobre nuestros servicios de acuerdo a lo que consideramos son los intereses de los Clientes.
    li Para enviarle avisos o información sobre la difusión de nuestros eventos o servicios.
    li Publicaciones en redes sociales.

  .container_profile_privacyAndTerms_subtitle Contraseña
  .container_profile_privacyAndTerms_text.
    Nuestros Clientes no deben revelar a nadie su contraseña; en todo momento, el Cliente es el responsable único y final de mantener en secreto su número de usuario, así como su contraseña personal, clave de acceso y número confidencial para poder acceder a nuestros servicios.
    <br/><br/>
    Los datos que cada Cliente proporcione estarán debidamente resguardados por programas que se encuentran en un servidor seguro.

  .container_profile_privacyAndTerms_subtitle Protección a menores
  .container_profile_privacyAndTerms_text.
    La Sociedad no solicita información personal a menores de edad.
    <br/><br/>
    Se recomienda que un menor de edad solicite autorización de sus padres para acceder a nuestro portal.

  .container_profile_privacyAndTerms_subtitle Cambios futuros a este Aviso de Privacidad
  .container_profile_privacyAndTerms_text.
    La Sociedad se reserva el derecho de enmendar o modificar el presente Aviso de Privacidad como estime conveniente para cumplir con cambios a la legislación sobre protección de datos o cumplir con disposiciones internas de la Sociedad. Asimismo, le informará y pondrá a su disposición el Aviso de Privacidad actualizado cuando se le hagan cambios significativos al mismo, así como cuando se requiera recabar su consentimiento conforme a la Ley de Datos Personales, en un plazo no mayor a 60 días a partir de la fecha de modificación. La versión actualizada del Aviso de Privacidad estará disponible en el sitio web.
    <br/><br/>
    Si este aviso llega a modificarse, podrá ser consultado aquí mismo. Para cualquier duda, comunicarse a <strong>lcastr60@gmail.com</strong>

  .container_profile_privacyAndTerms_subtitle Derechos que le corresponden:
  .container_profile_privacyAndTerms_text.
    Usted como titular de datos personales podrá ejercitar ante La Sociedad los derechos de acceso, rectificación, cancelación y oposición (“Derechos ARCO”) respecto al tratamiento de sus datos personales. Asimismo, podrá revocar, en todo momento, el consentimiento que haya otorgado y que fuere necesario para el tratamiento de sus datos personales. A manera de referencia, a continuación se describen los Derechos ARCO:
    <br/><br/>
    a) A través de su derecho de acceso usted podrá solicitar se le informe qué tipo de datos personales están siendo tratados, el origen de dichos datos y las comunicaciones que se hayan realizado con los mismos.
    <br/>
    b) En virtud del derecho de rectificación usted puede solicitar se corrija o complete los datos personales que sean incorrectos o estén incompletos. Usted tendrá el derecho de rectificar sus datos en caso de ser inexactos y de instruir a hacerse un cambio o corrección en sus datos personales y este hecho solo sea de su conocimiento.
    <br/>
    c) Cuando sus datos personales hayan dejado de ser necesarios para los fines para los cuales se recabaron, usted podrá solicitar que los mismos sean cancelados si es que aún se conservaran en las bases de datos de la Sociedad. El proceso de cancelación irá precedido de un periodo de bloqueo mediante el cual únicamente se almacenarán sus datos por un tiempo, equivalente al plazo de prescripción de las acciones que dieron origen al tratamiento de sus datos personales o el periodo que por ley se establezca. Existen casos en que los datos personales no pueden ser cancelados por disposición de ley.
    <br/>
    d) Podrá oponerse al tratamiento de sus datos personales, cuando exista una causa legítima para ello, incluso habiendo expresado anteriormente su consentimiento para dicho tratamiento.
    <br/><br/>
    Usted podrá solicitar el ejercicio de los Derechos ARCO o revocar su consentimiento al tratamiento de los datos personales por medio del envío de un correo electrónico, dirigido al  <strong>lcastr60@gmail.com</strong> proporcionándonos la siguiente información:
    <br/><br/>
    Documentos que acrediten su personalidad o la de su representante legal;
    <br/><br/>
    Descripción de los datos personales respecto de los cuales busca ejercer alguno de los Derechos ARCO, así como los Derechos ARCO a ejercer; y 
    <br/><br/>
    Cualquier otro elemento que facilite la localización de los datos personales respecto de los cuales busca ejercer los Derechos ARCO.
    <br/><br/>
    Una vez realizada la solicitud de Derechos ARCO, la Sociedad contará con un plazo de 20 (veinte) días hábiles para dar respuesta sobre la procedencia o improcedencia de la misma. En caso de que esta resultara procedente, la Sociedad contará con un plazo adicional de 15 (quince) días hábiles para conceder lo solicitado. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.

  .container_profile_privacyAndTerms_subtitle Uso de Cookies
  .container_profile_privacyAndTerms_text.
    Le informamos que la página web de la Sociedad utiliza cookies, web-beacons y otras tecnologías análogas, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en la página web. El Titular puede desactivar las cookies en su navegador, borrarlas o gestionar su uso a través de la configuración de su navegador en cualquier momento, en el entendido de que dichas acciones podrían ocasionar una deficiencia en el rendimiento y/o funcionamiento de la página web, derivado del hecho de que en la misma se ofrecen ciertas funcionalidades que solamente están disponibles mediante el empleo de cookies.

  .container_profile_privacyAndTerms_subtitle Aceptación de Términos
  .container_profile_privacyAndTerms_text.
    Los términos aquí manifestados están sujetos a las políticas y condiciones de la Sociedad, y constituye un acuerdo legal con el usuario, quien acepta haber leído y aceptado los términos que aqui se exponen. Quien no esté de acuerdo puede no dar ningún dato ni utilizar nuestros servicios.
    <br/><br/>
    Le recordamos que no solicitaremos información que no necesitamos para realizar nuestras actividades. Siempre trataremos de proteger la información proporcionada mediante servidores seguros, y nunca la compartiremos con terceros.
    <br/><br/>
    Al hacer uso de esta aplicación, está aceptando los términos y condiciones establecidos en este Aviso de Privacidad, incluyendo la recolección, tratamiento y transferencia.
</template>

<script>
export default {
  name: 'Privacy',
}
</script>