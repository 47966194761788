<template lang='pug'>
.container_login
  .container_login_title {{ $t("menu.recover.title") }}

  validation-observer.container_login_form(v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit)')
      validation-provider.container_login_form_container_provider(rules='required|email', name='correo', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='username', :placeholder='$t("menu.login.input.user")')
          img(src='~@/assets/images/mail.svg', alt='mail')

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button(:disabled='isLoading') {{ $t("menu.login.input.next") }}

  .container_login_link
    .container_login_link_text {{ $t("menu.signup.account") }} #[a.container_login_link_bold(@click='logIn') {{ $t("menu.signup.registry") }}]
    .container_login_link_text {{ $t("menu.login.account") }} #[a.container_login_link_bold(@click='singUp') {{ $t("menu.login.registry") }}]
</template>

<script>
export default {
  name: 'Recover',
  props: ['isLoading'],
  methods: {
    async submit() {
      try {
        await this.$parse.User.requestPasswordReset(this.username)

        this.$eventBus.$emit('alert', {
          type: 'info',
          message: 'Se ha enviado un link a tu correo electronico',
        })
      } catch (error) {
        this.$eventBus.$emit('alert', {
          type: 'danger',
          message: 'Error al recuperar tu contraseña, intentalo de nuevo',
          error,
        })
      }
    },
    logIn() {
      this.$emit('changePerform', 'login')
    },
    singUp() {
      this.$emit('changePerform', 'signup')
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
  data() {
    return {
      username: null,
      errors: '',
    }
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.username = 'luis@wearetorque.com'
    }
  },
}
</script>