<template lang='pug'>
.container_list(v-if='this.currentRoute() === "home"')
  .container_list_results(v-if='this.search')
    .container_list_results_text {{ $t("list.results") }} {{ filteredBooks.length }}

    .container_list_results_button(@click='clearSearch')
      .container_list_results_button_text {{ $t("list.close") }}
      img(src='~@/assets/images/x-circle.svg', alt='close')

  .container_list_results_not_found(v-if='this.search && filteredBooks.length === 0') {{ $t("list.notFoundHome") }}

  .container_list_books(v-if='books.length > 0')
    .container_list_book(v-for='book in filteredBooks', :key='book.id', @click='$router.push("/tienda/" + book.id)')
      img(:src='book.cover')
      .container_list_book_title {{ book.title }}

  .container_list_loading(v-else)
    .loading

.container_list(v-else-if='this.currentRoute() === "library"')
  .container_list_loading.middle(v-if='userBooks.length === 0 && isLoading')
    .loading

  .container_list_books(v-if='userBooks.length > 0')
    .container_list_book(v-for='book in filteredUserBooks', :key='book.id', @click='$router.push("/pdf/" + book.id)')
      img(:src='book.cover')
      .container_list_book_title {{ book.title }}

  .container_list_results_not_found(v-if='userBooks.length === 0 && !isLoading')
    img.result_library_image(src='~@/assets/images/no-books.png')
    .result_library {{ $t("list.notFoundLibrary") }}

.container_list(v-else-if='this.currentRoute() === "store"')
  .container_list_books_store(v-if='booksStore.length > 0')
    .container_list_book(v-for='book in booksStore', :key='book.id', @click='selectBook(book)')
      img(:src='book.cover')
      .container_list_book_title {{ book.title }}

  .container_list_results_not_found(v-else)
    .result_library {{ $t("list.notFoundStore") }}
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'List',
  props: ['booksStore'],
  computed: {
    ...mapState('user', ['userBooks', 'userCurrentCategory']),
    ...mapState('app', ['books', 'currentCategory', 'search']),
    filteredBooks() {
      if (!this.currentCategory) return this.books.filter((book) => !this.search || book.title.toLowerCase().includes(this.search)).sort((a, b) => (a.title > b.title ? 1 : -1))

      return this.currentCategory.books.filter((book) => !this.search || book.title.toLowerCase().includes(this.search)).sort((a, b) => (a.title > b.title ? 1 : -1))
    },

    filteredUserBooks() {
      if (!this.userCurrentCategory) {
        return this.userBooks
      }

      return this.userBooks.filter((book) => book.category.name === this.userCurrentCategory.name)
    },
  },
  methods: {
    ...mapMutations('app', ['setSearch']),
    ...mapActions('user', ['fetchUserBooks']),
    clearSearch() {
      this.setSearch(null)
    },
    currentRoute() {
      let path = this.$router.currentRoute.fullPath

      if (path.includes('/mis-libros')) {
        return 'library'
      } else if (path.includes('/tienda')) {
        return 'store'
      } else {
        return 'home'
      }
    },
    selectBook(book) {
      if (book.id !== this.$route.params.bookId) {
        this.$router.push('/tienda/' + book.id)
        this.$emit('reload')
      }
    },
  },
  watch: {
    userBooks() {
      this.isLoading = false
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  async mounted() {
    if (this.currentRoute() === 'library') {
      this.isLoading = true
      let response = await this.fetchUserBooks()

      if (!response) {
        this.isLoading = false
      }
    }
  },
}
</script>