import Parse from 'parse'

export default class Book extends Parse.Object {
  constructor() {
    super('Book')
  }

  get title() {
    return this.get('title')
  }

  get author() {
    return this.get('author')
  }

  get description() {
    return this.get('description')
  }

  get digitalPrice() {
    return this.get('digitalPrice')
  }

  get physicalPrice() {
    return this.get('physicalPrice')
  }

  get digitalPhysicalPrice() {
    return this.get('digitalPhysicalPrice')
  }

  get cover() {
    return this.get('cover') ? this.get('cover').url() : 'https://placekitten.com/200/300'
  }

  get category() {
    return this.get('category')
  }

  get enabled() {
    return this.get('enabled')
  }

  static loadEnabled() {
    return new Parse.Query(this).equalTo('enabled', true).find()
  }

  static getById(id) {
    return new Parse.Query(this).get(id)
  }
}

Parse.Object.registerSubclass('Book', Book)
