<template lang='pug'>
.container_login
  img.container_login_validate(src='~@/assets/images/robot.svg', alt='robot')
  .container_login_text {{ $t("menu.schoolCode.validate") }}
</template>

<script>
export default {
  name: 'Validate',
}
</script>