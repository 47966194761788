<template lang="pug">
.alert_content
  b-alert(v-if='message', show, dismissible, :variant='type') {{ message }}
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      type: null,
      message: null,
    }
  },
  methods: {
    showAlert() {
      setTimeout(() => {
        this.type = null
        this.message = null
      }, 5000)
    },
  },
  watch: {
    message(value) {
      if (value) {
        this.showAlert()
      }
    },
  },
  mounted() {
    this.$eventBus.$on('alert', (params) => {
      this.type = params.type
      this.message = params.message
    })
  },
}
</script>