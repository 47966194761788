<template lang='pug'>
.container_profile_info
  .container_profile_info_title {{ $t("profile.info.title") }}

  validation-observer(v-slot='{handleSubmit, errors}')
    form.container_profile_info_form(@submit.prevent='handleSubmit(update)')
      validation-provider.container_profile_info_form_input(rules='required|alpha_spaces|min:4', name='nombre', v-slot='{valid, dirty}')
        .container_profile_info_form_input_text {{ $t("profile.info.name") }}
        input.container_profile_info_form_input_value(v-model='name', :placeholder='user.name')

      button.container_profile_info_form_button
        img(src='~@/assets/images/update.svg', alt='update')

    .container_profile_info_errors(v-if='parseErrors(errors).length !== 0')
      .container_profile_info_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Information',
  data() {
    return {
      name: null,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    ...mapActions('user', ['setName']),
    async update() {
      if (this.name) {
        let response = await this.setName(this.name)

        if (!response.status) {
          this.$eventBus.$emit('alert', {
            type: 'danger',
            message: 'Ocurrio un error, intentalo de nuevo',
          })
        } else {
          this.$eventBus.$emit('alert', {
            type: 'info',
            message: 'Información actualizada',
          })
        }
      }
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
}
</script>