var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container_login"},[_c('div',{staticClass:"container_login_title recover_title"},[_vm._v(_vm._s(_vm.$t("menu.recover.recover")))]),(!_vm.status)?_c('div',{staticClass:"container_login_link"},[_c('div',{staticClass:"container_login_link_text"},[_vm._v(_vm._s(_vm.$t("menu.recover.fields")))])]):_c('div',{staticClass:"container_login_link"},[_c('div',{staticClass:"container_login_link_text"},[_vm._v(_vm._s(_vm.$t("menu.recover.success")))])]),(!_vm.status)?_c('validation-observer',{staticClass:"container_login_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('form',{staticClass:"container_login_form_container",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{ref:"contraseña",staticClass:"container_login_form_container_provider",attrs:{"rules":"required","name":"contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"type":"password","placeholder":_vm.$t("menu.recover.input.a")},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/password.svg"),"alt":"logo"}})])]}}],null,true)}),_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required|confirmed:contraseña","name":"confirmar contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid },attrs:{"type":"password"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirm),expression:"password_confirm"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"type":"password","placeholder":_vm.$t("menu.recover.input.b")},domProps:{"value":(_vm.password_confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirm=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/password.svg"),"alt":"logo"}}),_c('img',{attrs:{"src":require("@/assets/images/password.svg"),"alt":"logo"}})])]}}],null,true)}),_c('div',{staticClass:"container_login_form_errors"},_vm._l((_vm.parseErrors(errors)),function(error){return (_vm.parseErrors(errors))?_c('div',{staticClass:"container_login_form_errors_text"},[_vm._v(_vm._s(error))]):_vm._e()}),0),_c('button',{staticClass:"container_login_form_button",attrs:{"disable:":"","isLoading":""}},[_vm._v(_vm._s(_vm.$t("menu.recover.recover")))])],1)]}}],null,false,3804669470)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }