<template lang="pug">
.sidebar
  .sidebar_link
    router-link(:to={name: 'Home'})
      img(src="~@/assets/images/logo.svg", alt="logo")

  .sidebar_logo
    router-link(:to={name: 'Home'})
      //- img(src="~@/assets/images/logo.svg", alt="logo")
    button(@click='stateSidebar')
      img(src="~@/assets/images/menu.svg", alt="menu")

  .sidebar_title
    img(src="~@/assets/images/user-circle.svg", alt="profile")
    .sidebar_title_text {{ $t('sidebar.profile.title') }}

  .sidebar_options
    router-link(:to={name: 'Information'}) {{ $t('sidebar.profile.options.a') }}
    router-link(:to={name: 'Billing'}) {{ $t('sidebar.profile.options.b') }}
    router-link(:to={name: 'Privacy'}) {{ $t('sidebar.profile.options.c') }}

  .sidebar_bird_profile
    img(src="~@/assets/images/bird.svg", alt="bird")

  .sidebar_terms_profile {{ $t('sidebar.terms') }}
</template>

<script>
export default {
  name: 'ProfileSide',
  methods: {
    stateSidebar() {
      this.$emit('stateSidebar')
    },
  },
}
</script>