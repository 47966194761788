<template lang='pug'>
.container_carousel
  slick(ref='slick', :options='slickOptions', @afterChange='handleAfterChange')
    .slide(v-for='slide in slides')
      .slide_title(v-html='$t(`carousel.${slide}.title`)')
      .slide_text(v-html='$t(`carousel.${slide}.text`)')

  .buttons_carousel
    .arrow-right(@click='next')
      img(src='~@/assets/images/arrow-right.svg', alt='right')
    .arrow-left(@click='prev')
      img(src='~@/assets/images/arrow-left.svg', alt='left')
</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'Carousel',
  components: {
    Slick,
  },
  data() {
    return {
      slides: ['a', 'b', 'c'],
      currentIndex: 1,
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        dots: false,
        draggable: true,
        swipe: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
      },
    }
  },
  computed: {
    currentSlide() {
      return 1
    },
  },
  methods: {
    next() {
      this.$refs.slick.next()
    },
    prev() {
      this.$refs.slick.prev()
    },
    handleAfterChange(event, slick, currentSlide) {
      this.currentIndex = currentSlide + 1
    },
  },
}
</script>