<template lang="pug">
.container_home.not
  img.notFound(src='~@/assets/images/404.png')
  .textNotFound {{ $t("content.notFound") }}
</template>

<script>
export default {
  name: 'NotFound',
}
</script>