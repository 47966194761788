<template lang="pug">
.container_login
  .container_login_title.purchase_title {{ $t("purchase.payment.confirm.title") }}

  .container_login_book {{ purchaseOption.book.title }}

  .container_login_section(v-if='purchaseOption.option !== "digital"')
    .container_login_section_info
      .container_login_section_info_title {{ $t("purchase.payment.confirm.direction") }}
      .container_login_section_info_data {{ Object.values(directionData).map((value) => value).join(", ") }}
    .container_login_section_button(@click='changeDirection') {{ $t("purchase.payment.confirm.change") }}

  .container_login_section
    .container_login_section_info
      .container_login_section_info_title {{ $t("purchase.payment.confirm.payment") }}
      .container_login_section_info_data {{ brand }} (**** {{ lastFour }})
    .container_login_section_button(@click='changePayment') {{ $t("purchase.payment.confirm.change") }}

  .container_login_section(v-if='purchaseOption.option === "digital"')
    .container_login_section_info
      .container_login_section_info_title {{ $t("purchase.payment.confirm.digital") }}
      .container_login_section_info_data ${{ purchaseOption.book.digitalPrice }}

  .container_login_section(v-if='purchaseOption.option === "physical"')
    .container_login_section_info
      .container_login_section_info_title {{ $t("purchase.payment.confirm.physical") }}
      .container_login_section_info_data ${{ purchaseOption.book.physicalPrice }} {{ $t("store.send") }}

  .container_login_section(v-if='purchaseOption.option === "both"')
    .container_login_section_info
      .container_login_section_info_title {{ $t("purchase.payment.confirm.both") }}
      .container_login_section_info_data ${{ purchaseOption.book.digitalPhysicalPrice }} {{ $t("store.send") }}

  .container_login_total {{ $t("purchase.payment.confirm.total") }}
  .container_login_price ${{ total }}

  .container_login_form
    .container_login_form_container
      button.container_login_form_button(@click='submit', :disable='isLoading') {{ $t("purchase.payment.confirm.next") }}

  .container_login_terms
    .container_login_terms_text {{ $t("purchase.payment.confirm.conditions") }} #[a.container_login_terms_text_link(href='/aviso-de-privacidad', target='_blank') {{ $t("purchase.payment.confirm.terms") }}]
</template>

<script>
export default {
  name: 'ConfirmPayment',
  props: ['purchaseOption', 'directionData', 'paymentData', 'card', 'token'],
  data() {
    return {
      send: 70,
      lastFour: '',
      brand: '',
      error: null,
      isLoading: false
    }
  },
  computed: {
    total() {
      if (this.purchaseOption.option === 'digital') {
        return this.purchaseOption.book.digitalPrice
      } else if (this.purchaseOption.option === 'physical') {
        return this.purchaseOption.book.physicalPrice + 70
      } else {
        return this.purchaseOption.book.digitalPhysicalPrice + 70
      }
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      this.error = null
      let format = ''
      let address = this.directionData
        ? Object.entries(this.directionData)
            .map(value => (value[0] !== 'references' ? value[1] : null))
            .filter(value => value !== null)
            .join(', ')
        : null

      switch (this.purchaseOption.option) {
        case 'digital':
          format = 'Digital'
          break
        case 'physical':
          format = 'Físico'
          break
        case 'both':
          format = 'Digital y Físico'
          break
      }

      let params = this.directionData
        ? {
            method: 'card',
            book: this.purchaseOption.book.id,
            format,
            tokenCard: this.token ? this.token.id : this.card.token,
            address,
            additionalReference: this.directionData.references ? this.directionData.references : 'Sin referencias'
          }
        : {
            method: 'card',
            book: this.purchaseOption.book.id,
            format,
            tokenCard: this.token ? this.token.id : this.card.token
          }

      try {
        await this.$parse.Cloud.run('createOrder', params)
        this.isLoading = false
        this.$emit('final')
      } catch (error) {
        if (this.token) {
          this.isLoading = false
          this.$emit('final')
        } else {
          if (error.message.includes('The cards has been declined. Please, try again.')) {
            this.$eventBus.$emit('alert', {
              type: 'danger',
              message: 'Tarjeta declinada. Intente nuevamente con otra tarjeta'
            })
          } else if (error.message.includes('There is insufficient funds in this card. Please, try again.')) {
            this.$eventBus.$emit('alert', {
              type: 'danger',
              message: 'Tarjeta con fondos insuficientes. Intente nuevamente con otra tarjeta'
            })
          } else {
            this.$eventBus.$emit('alert', {
              type: 'danger',
              message: 'Ha ocurrido un error con esta tarjeta. Intente nuevamente con otra tarjeta'
            })
          }

          this.isLoading = false
          this.$emit('returnSection')
        }
      }
    },
    changeDirection() {
      this.$emit('changeDirection')
    },
    changePayment() {
      this.$emit('returnSection')
    }
  },
  mounted() {
    if (this.token) {
      this.lastFour = this.token.lastFour
      this.brand = this.token.brand
    }

    if (this.card) {
      this.lastFour = this.card.lastFour
      this.brand = this.card.brand
    }
  }
}
</script>
