import Parse from 'parse'

export default class User extends Parse.User {
  constructor() {
    super('_User')
  }

  get username() {
    return this.getUsername()
  }

  get email() {
    return this.username
  }

  get name() {
    return this.get('name')
  }

  static async setName(name) {
    let user = await new Parse.Query(this).get(this.current().id)
    user.set('name', name)
    return await user.save()
  }

  static findUsers() {
    return new Parse.Query(this).find()
  }

  static findRole(role) {
    return new Parse.Query('_Role').equalTo('name', role).find()
  }

  async getCards() {
    return await this.relation('cards')
      .query()
      .find()
  }
}

Parse.Object.registerSubclass('_User', User)
