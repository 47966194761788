<template lang="pug">
.container_global
  .content.recover
    .content_header_home.recover
      .content_logo
        router-link(:to={name: 'Home'})
          img(src="~@/assets/images/logo.svg", alt="logo")
        button(@click='$router.push("/")')
          img(src="~@/assets/images/menu.svg", alt="menu")
    .content_form
      .container_login
        .container_login_title.recover_title {{ $t('menu.recover.token-title') }}

        .container_login_link
          .container_login_link_text {{ $t('menu.recover.token-error') }}
</template>

<script>
export default {
  name: 'Invalid',
}
</script>