<template lang="pug">
.container_login
  .container_login_title.purchase_title {{ $t("purchase.payment.title") }}

  .container_login_payment
    .container_login_payment_option(@click='selectOption("oxxo")', :class='{ selectedOption: optionSelected === "oxxo" }')
      .container_login_payment_option_container_image
        img.container_login_payment_option_container_image_img(src='~@/assets/images/oxxo.svg', alt='oxxo')

      .container_login_payment_option_container_info
        .container_login_payment_option_container_info_title {{ $t("purchase.payment.oxxo.title") }}
        .container_login_payment_option_container_info_type {{ $t("purchase.payment.oxxo.type") }}

      .container_login_payment_option_container_tick(v-if='optionSelected === "oxxo"')
        img(src='~@/assets/images/tick.svg', alt='tick')

    .container_login_payment_option_cards(v-for='card in userCards', :key='card.id')
      .container_login_payment_option(@click='selectOption(card.id)', :class='{ selectedOption: optionSelected === card.id }')
        .container_login_payment_option_container_image
          img.container_login_payment_option_container_image_img(v-if='card.brand === "visa"', src='~@/assets/images/visa.png', alt='visa')
          img.container_login_payment_option_container_image_img(v-if='card.brand === "mastercard"', src='~@/assets/images/mastercard.png', alt='mastercard')
          img.container_login_payment_option_container_image_img(v-if='card.brand === "american_express"', src='~@/assets/images/amex.png', alt='americanExpress')

        .container_login_payment_option_container_info
          .container_login_payment_option_container_info_title **** {{ card.lastFour }}
          .container_login_payment_option_container_info_type {{ card.brand.split("_").join(" ") }}

        .container_login_payment_option_container_tick(v-if='optionSelected === card.id')
          img(src='~@/assets/images/tick.svg', alt='tick')

    .container_login_payment_option(@click='selectOption("plus")', :class='{ selectedOption: optionSelected === "plus" }')
      .container_login_payment_option_container_image
        img.container_login_payment_option_container_image_img(src='~@/assets/images/plus.svg', alt='plus')

      .container_login_payment_option_container_info
        .container_login_payment_option_container_info_title {{ $t("purchase.payment.plus.title") }}
        .container_login_payment_option_container_info_type {{ $t("purchase.payment.plus.type") }}

      .container_login_payment_option_container_tick(v-if='optionSelected === "plus"')
        img(src='~@/assets/images/tick.svg', alt='tick')

  .container_login_error(v-if='error') {{ error }}

  .container_login_form
    .container_login_form_container
      button.container_login_form_button(@click='submit', :disable='isLoading') {{ $t("purchase.next") }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SelectPayment',
  props: ['isLoading'],
  computed: {
    ...mapState('user', ['userCards']),
  },
  methods: {
    ...mapActions('user', ['fetchUserCards']),
    selectOption(option) {
      this.error = null
      this.optionSelected = option
    },
    submit() {
      if (this.optionSelected !== null) {
        if (this.optionSelected !== 'oxxo' && this.optionSelected !== 'plus') {
          let card = this.userCards.find((card) => card.id === this.optionSelected)
          this.$emit('optionPayment', card)
        } else {
          this.$emit('optionPayment', this.optionSelected)
        }
      } else {
        this.error = 'Selecciona una opcion'
      }
    },
  },
  data() {
    return {
      optionSelected: null,
      error: null,
    }
  },
  mounted() {
    this.fetchUserCards()
  },
}
</script>