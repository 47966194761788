import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import Parse from './parse'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import es from 'vee-validate/dist/locale/es.json'
import 'slick-carousel/slick/slick.css'
import VueMask from 'v-mask'

localize('es', es)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.prototype.$parse = Parse
Vue.config.productionTip = false
Vue.use(VueMask)

// eslint-disable-next-line
Conekta.setPublicKey(process.env.VUE_APP_CONEKTA_PUBLIC_KEY)
// eslint-disable-next-line
Conekta.setLanguage('es')
// eslint-disable-next-line
Vue.prototype.$conekta = Conekta

Vue.prototype.$eventBus = new Vue()

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
