<template lang="pug">
.container_sidebar(:class='{ sidebar_hide: !statusPurchase }')
  .container_sidebar_back(@click='changeClass')

  .container_sidebar_menu
    .container_sidebar_menu_buttons.space_buttons(v-if='currentSection !== "finalPayment"')
      .container_sidebar_menu_buttons_button(@click='returnSection')
        img(src='~@/assets/images/return.svg', alt='close')
        .container_sidebar_menu_button_text {{ $t("purchase.return") }}

      .container_sidebar_menu_buttons_button(@click='changeClass')
        .container_sidebar_menu_button_text {{ $t("purchase.close") }}
        img(src='~@/assets/images/x-circle.svg', alt='close')

    Direction(v-if='user && currentSection === "direction"', @direction='direction', :data='directionData', :isLoading='isLoading')

    SelectPayment(v-if='user && currentSection === "selectPayment"', @optionPayment='optionPayment', :isLoading='isLoading')

    Ticket(v-if='user && currentSection === "formatOxxo"', @final='final', :purchaseOption='this.purchaseOption', :directionData='this.directionData', :exit='changeClass')

    AddCard(v-if='user && currentSection === "addCard"', @save='saveCard', :isLoading='isLoading')

    ConfirmPayment(
      v-if='user && currentSection === "confirmPayment"',
      :purchaseOption='this.purchaseOption',
      :directionData='this.directionData',
      :paymentData='this.paymentData',
      :card='selectedCard',
      :token='token',
      @returnSection='returnSection',
      @changeDirection='changeDirection',
      @final='final',
    )

    FinalPayment(v-if='user && currentSection === "finalPayment"', @exit='changeClass', :typePayment='paymentData', :payment='purchaseOption')
</template>

<script>
import { mapState } from 'vuex'
import Direction from '@/components/forms/Direction'
import SelectPayment from '@/components/forms/SelectPayment'
import Ticket from '@/components/forms/Ticket'
import AddCard from '@/components/forms/AddCard'
import ConfirmPayment from '@/components/forms/ConfirmPayment'
import FinalPayment from '@/components/forms/FinalPayment'

export default {
  name: 'Purchase',
  components: {
    Direction,
    SelectPayment,
    Ticket,
    AddCard,
    ConfirmPayment,
    FinalPayment,
  },
  props: ['purchaseOption', 'purchaseShow'],
  data() {
    return {
      currentSection: null,
      directionData: null,
      paymentData: null,
      isLoading: false,
      statusPurchase: false,
      changeInPayment: false,
      selectedCard: null,
      token: null,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    changeClass() {
      this.statusPurchase = false
      setTimeout(() => {
        this.$emit('statePurchase')
      }, 250)
    },
    returnSection() {
      if (this.currentSection === 'direction') {
        this.$emit('statePurchase')
      } else if (this.currentSection === 'selectPayment' && this.purchaseOption.option === 'digital') {
        this.$emit('statePurchase')
      } else if (this.currentSection === 'selectPayment' && this.purchaseOption.option !== 'digital') {
        this.currentSection = 'direction'
      } else if (this.currentSection === 'formatOxxo' || this.currentSection === 'addCard' || this.currentSection === 'confirmPayment') {
        this.currentSection = 'selectPayment'
      } else if (this.currentSection === 'addCard') {
        this.currentSection = 'selectPayment'
      }
    },
    direction(data) {
      this.directionData = data
      this.isLoading = true

      if (this.changeInPayment) {
        this.currentSection = 'confirmPayment'
        this.changeInPayment = false
        this.isLoading = false
      } else {
        this.currentSection = 'selectPayment'
        this.isLoading = false
      }

      this.isLoading = false
    },
    changeDirection() {
      this.changeInPayment = true
      this.currentSection = 'direction'
    },
    optionPayment(data) {
      this.isLoading = true

      if (data === 'oxxo') {
        this.paymentData = 'oxxo'
        this.currentSection = 'formatOxxo'
        this.isLoading = false
      } else if (data === 'plus') {
        this.currentSection = 'addCard'
        this.selectedCard = null
        this.isLoading = false
      } else {
        this.paymentData = data
        this.selectedCard = data
        this.currentSection = 'confirmPayment'
        this.isLoading = false
      }

      this.isLoading = false
    },
    async saveCard(data) {
      try {
        this.isLoading = true
        let { token, lastFour, brand } = await this.createToken(data)

        if (data.save) {
          await this.$parse.Cloud.run('manageUserCards', {
            event: 'add',
            tokenCard: token,
          })

          this.returnSection()
        } else {
          this.token = { id: token, lastFour, brand }
          this.paymentData = data
          this.currentSection = 'confirmPayment'
        }

        this.isLoading = false
      } catch (error) {
        this.$eventBus.$emit('alert', {
          type: 'danger',
          message: error.message_to_purchaser,
        })

        this.currentSection = 'selectPayment'
        this.isLoading = false
      }
    },
    async createToken(data) {
      const { numberCard: number, nameCard: name, cvc, expirationDate: expiration } = data

      let card = {
        number: number.split(' ').join(''),
        name,
        exp_month: expiration.split('/')[0],
        exp_year: '20' + expiration.split('/')[1],
        cvc,
      }

      let brand = this.$conekta.card.getBrand(card.number)

      return new Promise((resolve, reject) => {
        this.$conekta.Token.create(
          { card },
          (token) =>
            resolve({
              token: token.id,
              lastFour: card.number.substr(-4),
              brand,
            }),
          (error) => reject(error)
        )
      })
    },
    final() {
      this.currentSection = 'finalPayment'
    },
  },
  mounted() {
    this.statusPurchase = this.purchaseShow

    if (this.purchaseOption.option === 'digital') {
      this.currentSection = 'selectPayment'
    } else {
      this.currentSection = 'direction'
    }
  },
}
</script>