<template lang='pug'>
.container_home
  .container_home_list
    List
</template>

<script>
import List from '@/components/List'

export default {
  name: 'Library',
  components: {
    List,
  },
}
</script>
