import Category from '@/parse/models/Category'
import Book from '@/parse/models/Book'
import Institute from '@/parse/models/Institute'
import Parse from '@/parse'

let state = {
  categories: [],
  books: [],
  currentCategory: null,
  search: null,
  institutes: []
}

let getters = {
  categoryById: state => id => state.categories.find(category => category.id == id),
  bookById: state => id => state.books.find(book => book.id == id),
  institutes: state => state.institutes,
}

let actions = {
  async loadData(store) {
    let categories = await Category.loadEnabled()
    let books = await Book.loadEnabled()
    let institutes = await Institute.findInstitutes()
    store.commit('setData', { categories, books, institutes })
  },

  async paymentOxxo(store, { book, format, address, additionalReference }) {
    try {
      store

      if (format === 'digital') {
        let response = await Parse.Cloud.run('createOrder', {
          method: 'oxxo',
          book,
          format: 'Digital'
        })

        return { status: true, response }
      } else if (format === 'physical') {
        let response = await Parse.Cloud.run('createOrder', {
          method: 'oxxo',
          book,
          format: 'Físico',
          address,
          additionalReference
        })

        return { status: true, response }
      } else {
        let response = await Parse.Cloud.run('createOrder', {
          method: 'oxxo',
          book,
          format: 'Digital y Físico',
          address,
          additionalReference
        })

        return { status: true, response }
      }
    } catch (error) {
      return { status: false, error }
    }
  }
}

let mutations = {
  setData(state, { categories, books, institutes }) {
    state.categories = categories
    state.books = books
    state.institutes = institutes
  },

  setCurrentCategory(state, category) {
    state.currentCategory = category
  },

  setSearch(state, search) {
    state.search = search
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
