<template lang="pug">
.container_login
  .container_login_title.purchase_title {{ $t("purchase.addCard.title") }}

  validation-observer.container_login_form(v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit)')
      validation-provider.container_login_form_container_provider(rules='required', name='número de tarjeta', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='numberCard', v-mask='isAmex ? \'#### ###### #####\' : \'#### #### #### ####\'', :placeholder='$t("purchase.addCard.numberCard")')
          img(src='~@/assets/images/card.svg', alt='card')

      validation-provider.container_login_form_container_provider(rules='required|alpha_spaces', name='nombre en la tarjeta', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='nameCard', :placeholder='$t("purchase.addCard.nameCard")')

      validation-provider.container_login_form_container_provider(rules='required|length:5', name='vigencia de la tarjeta', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='expirationDate', v-mask='\'##/##\'', :placeholder='$t("purchase.addCard.expirationDate")')

      validation-provider.container_login_form_container_provider(rules='required|numeric', name='CVC', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='cvc', v-mask='\'####?\'', :placeholder='$t("purchase.addCard.cvc")')

      .container_login_form_container_save
        .container_login_form_container_save_text {{ $t("purchase.addCard.save") }}
        b-form-checkbox.mr-n2(switch, v-model='save')

      .container_login_form_cards
        img.container_login_form_cards_img(src='~@/assets/images/visa.png', alt='card')
        img.container_login_form_cards_img(src='~@/assets/images/mastercard.png', alt='card')
        img.container_login_form_cards_img(src='~@/assets/images/amex.png', alt='card')

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button(:disabled='isLoading') {{ $t("purchase.addCard.next") }}
</template>

<script>
export default {
  name: 'AddCard',
  props: ['isLoading'],
  computed: {
    isAmex() {
      return this.numberCard.startsWith('37')
    },
  },
  methods: {
    submit() {
      this.$emit('save', this.$data)
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
  data() {
    return {
      numberCard: '',
      nameCard: '',
      expirationDate: '',
      cvc: '',
      save: false,
    }
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.numberCard = '4242 4242 4242 4242'
      this.nameCard = 'Luis Torque'
      this.expirationDate = 1227
      this.cvc = 333
      this.save = true
    }
  },
}
</script>