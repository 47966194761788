<template lang="pug">
div
  #printMe(v-if='!isLoading', ref='printMe')
    .container_ticket
      .container_ticket_title(v-html='$t("purchase.payment.ticket.title")')

      img.container_ticket_image(:src='image', alt='oxxo')

      .container_ticket_instruction {{ $t("purchase.payment.ticket.payment") }}

      .container_ticket_payment ${{ this.total }}

      .container_ticket_instruction.italic {{ $t("purchase.payment.ticket.commission") }}

      .container_ticket_instruction {{ $t("purchase.payment.ticket.reference") }}

      .container_ticket_reference {{ this.references }}

      .container_ticket_subtitle {{ $t("purchase.payment.ticket.instructions.title") }}

      .container_ticket_instruction.space {{ $t("purchase.payment.ticket.instructions.a") }}
      .container_ticket_instruction.space {{ $t("purchase.payment.ticket.instructions.b") }}
      .container_ticket_instruction.space {{ $t("purchase.payment.ticket.instructions.c") }}
      .container_ticket_instruction.space {{ $t("purchase.payment.ticket.instructions.d") }}
      .container_ticket_instruction.space {{ $t("purchase.payment.ticket.instructions.e") }}

      .container_ticket_subtitle {{ $t("purchase.payment.ticket.verify") }}
      .container_ticket_subtitle {{ $t("purchase.payment.ticket.final") }}

      .container_ticket_button(@click='next', :disable='isLoading') {{ $t("purchase.payment.ticket.next") }}

      #mail.container_ticket_instruction.center {{ $t("purchase.payment.ticket.mail") }}

  .container_ticket.load(v-else)
    .loading
</template>

<script>
import { mapActions } from 'vuex'
import juice from 'juice'
import style from '@/styles/ticket.txt'

export default {
  name: 'Ticket',
  props: ['purchaseOption', 'directionData'],
  computed: {
    total() {
      if (this.purchaseOption.option === 'digital') {
        return this.purchaseOption.book.digitalPrice
      } else if (this.purchaseOption.option === 'physical') {
        return this.purchaseOption.book.physicalPrice + 70
      } else {
        return this.purchaseOption.book.digitalPhysicalPrice + 70
      }
    },
  },
  methods: {
    ...mapActions('app', ['paymentOxxo']),
    next() {
      this.print()
      this.$emit('final')
    },
    print() {
      let content = this.$refs['printMe']
      let inlined = juice.inlineContent(content.innerHTML, style)

      var win = window.open('', 'Print receipt', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top=' + (screen.height - 400) + ',left=' + (screen.width - 840))
      win.document.body.innerHTML = inlined
      win.print()
    },
  },
  watch: {
    references() {
      this.isLoading = false
    },
  },
  async mounted() {
    this.isLoading = true

    if (this.directionData) {
      let address = Object.entries(this.directionData)
        .map((value) => (value[0] !== 'references' ? value[1] : null))
        .filter((value) => value !== null)
        .join(', ')

      let additionalReference = this.directionData.references ? this.directionData.references : 'Sin referencias'

      let response = await this.paymentOxxo({
        book: this.purchaseOption.book.id,
        format: this.purchaseOption.option,
        address,
        additionalReference,
      })

      this.references = response.response.reference

      if (!response.status) {
        this.$eventBus.$emit('alert', {
          type: 'danger',
          message: 'Ocurrio un error al generar el ticket de pago',
        })

        this.isLoading = false
        this.$emit('exit')
      }
    } else {
      let response = await this.paymentOxxo({
        book: this.purchaseOption.book.id,
        format: this.purchaseOption.option,
      })

      this.references = response.response.reference

      if (!response.status) {
        this.$eventBus.$emit('alert', {
          type: 'danger',
          message: 'Ocurrio un error al generar el ticket de pago',
        })

        this.isLoading = false
        this.$emit('exit')
      }
    }
  },
  data() {
    return {
      references: null,
      isLoading: false,
      image: `${process.env.VUE_APP_BASE_URL}/img/oxxo.8b2cc5d4.svg`,
    }
  },
}
</script>