<template lang='pug'>
.container_login
  .container_login_title {{ $t("menu.schoolCode.title") }}

  .container_login_link
    .container_login_link_text {{ $t("menu.schoolCode.text") }}

  validation-observer.container_login_form(v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit_key)')
      validation-provider.container_login_form_container_provider(rules='required', name='clave', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='schoolCode', :placeholder='$t("menu.schoolCode.input")')
          img(src='~@/assets/images/password.svg', alt='key')

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button {{ $t("menu.schoolCode.buttons.a") }}

    button.container_login_form_button(@click='returnMenu') {{ $t("menu.schoolCode.buttons.c") }}
</template>

<script>
export default {
  name: 'SchoolCodeMenu',
  methods: {
    submit_key() {
      this.$emit('next', this.$data)
    },
    returnMenu() {
      this.$emit('returnMenu')
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
  data() {
    return {
      schoolCode: null,
    }
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.schoolCode = 'test'
    }
  },
}
</script>