<template lang='pug'>
.container_login
  img.container_login_validate(src='~@/assets/images/welcome.svg', alt='welcome')

  .container_login_text.text_left(v-if='schoolCode', v-html='$t("menu.schoolCode.welcomeBooks")')

  .container_login_text.text_left(v-else, v-html='$t("menu.schoolCode.welcome")')

  .container_login_form
    .container_login_form_container
      button.container_login_form_button(@click='submit') {{ $t("menu.schoolCode.buttons.a") }}
</template>

<script>
export default {
  name: 'Welcome',
  props: ['schoolCode'],
  methods: {
    submit() {
      this.$emit('next')
    },
  },
}
</script>