<template lang='pug'>
.container_pdf(@click.right='clickRight')
  .container_pdf_header
    .container_pdf_header_max
      .container_pdf_header_title {{ name }}

      img.container_pdf_header_button(src='~@/assets/images/x-circle-white.svg', alt='login', @click='close')

  .container_pdf_controls_page
    .container_pdf_controls_page_max
      .container_pdf_controls_page_button(@click='previous', :disabled='isLoading') Anterior

      .container_pdf_controls_page_button(@click='next', :disabled='isLoading') Siguiente

  .container_pdf_controls_zoom
    .container_pdf_controls_zoom_max
      .container_pdf_controls_zoom_button(@click='zoomIn') Acercar +
      .container_pdf_controls_zoom_porcent {{ zoom }} %
      .container_pdf_controls_zoom_button(@click='zoomOut') Alejar -

  .container_pdf_page
    .container_pdf_page_max
      .container_pdf_page_cont
        .container_pdf_page_text Página
        input.container_pdf_page_input(@change='findPage', :placeholder='currentPage')
        .container_pdf_page_text de {{ pages }}

  .container_pdf_viewer
    .container_pdf_viewer_page(ref='viewer', :class='{ container_zoom: zoom < 100 }')
      .container_pdf_viewer_error(v-if='error') {{ error }}
      canvas(v-else, ref='canvas', :style='canvasStyle')

  .container_pdf_loading(v-if='isLoading')
    .loading
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/build/pdf'

pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/build/pdf.worker.min.js'

export default {
  name: 'PdfViewer',
  data() {
    return {
      isLoading: true,
      name: null,
      pdfViewer: null,
      error: null,
      pdf: null,
      currentPage: 0,
      pages: 0,
      zoom: 50,
      inputPage: null,
    }
  },
  computed: {
    canvasStyle() {
      return { width: `${this.zoom}%` }
    },
    book() {
      return this.$route.params.bookId
    },
  },
  methods: {
    async fetchDocument() {
      try {
        let response = await this.$parse.Cloud.run('getUrlFile', {
          book: this.book,
        })

        this.name = response.book

        await this.loadPdf(response)
      } catch (error) {
        this.error = `No fue posible cargar el contenido: ${error.message}`
        this.isLoading = false
      }
    },
    async loadPdf({ url, password }) {
      var loadingTask = pdfjsLib.getDocument({
        // url: 'https://cors-anywhere.herokuapp.com/' + url,
        url,
        password,
      })

      try {
        this.pdf = await loadingTask.promise
      } catch (error) {
        this.error = `No fue posible cargar el contenido: ${error.message}`
        this.isLoading = false
      }

      this.currentPage = 1
      this.pages = this.pdf.numPages

      this.render()
    },
    async render() {
      let page = await this.pdf.getPage(this.currentPage)

      const container = this.$refs['viewer']
      const canvas = this.$refs['canvas']
      const canvasContext = canvas.getContext('2d')

      let viewport = page.getViewport(1)
      viewport = page.getViewport(container.offsetWidth / viewport.width)

      canvas.height = viewport.height
      canvas.width = viewport.width

      await page.render({ canvasContext, viewport })
      this.isLoading = false
    },
    next() {
      if (this.currentPage + 1 <= this.pages) {
        this.isLoading = true
        this.currentPage++
        this.inputPage = null
        this.render()
      }
    },
    previous() {
      if (this.currentPage - 1 > 0) {
        this.isLoading = true
        this.currentPage--
        this.inputPage = null
        this.render()
      }
    },
    zoomIn() {
      if (this.zoom + 10 <= 200) this.zoom += 10
    },
    zoomOut() {
      if (this.zoom - 10 >= 30) this.zoom -= 10
    },
    close() {
      this.$router.push({ name: 'Library' })
    },
    clickRight() {
      document.oncontextmenu = () => {
        return false
      }
    },
    findPage(event) {
      let value = event.target.value

      if (this.currentPage != value && value > 0 && value <= this.pages) {
        this.isLoading = true
        this.currentPage = Number(value)
        this.render()
      }
    },
  },
  async mounted() {
    this.fetchDocument()
  },
}
</script>