<template lang="pug">
.container_global
  .content.recover
    .content_header_home
      .content_logo
        router-link(:to={name: 'Home'})
          img(src="~@/assets/images/logo.svg", alt="logo")
        button(@click='$router.push("/")')
          img(src="~@/assets/images/menu.svg", alt="menu")
  .content_form
    Password
</template>

<script>
import Password from '../components/forms/Password'

export default {
  name: 'Recover',
  components: {
    Password,
  },
  mounted() {
    let query = this.$route.query

    this.token = query.token
    this.id = query.id
    this.username = query.username
    this.app = query.app

    if (!this.token || !this.id || !this.username || !this.app) {
      this.$router.push('/token-invalido')
    } else {
      this.$router.replace({ query: {} })
    }
  },
}
</script>