import Parse from 'parse'

export default class Card extends Parse.Object {
  constructor() {
    super('Card')
  }

  get lastFour() {
    return this.get('lastFour')
  }

  get brand() {
    return this.get('brand')
  }

  get token() {
    return this.get('token')
  }
}

Parse.Object.registerSubclass('Card', Card)
