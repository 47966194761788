<template lang="pug">
.container_login
  img.container_login_validate(src='~@/assets/images/welcome.svg', alt='welcome')

  .container_login_text.final_title {{ $t("purchase.payment.final.title") }}

  .container_login_text.final_instruction(v-if='typePayment === "oxxo"') {{ $t("purchase.payment.final.oxxo") }}

  .container_login_text.final_instruction(v-else, v-html='$t("purchase.payment.final.card")')

  .container_login_form
    .container_login_form_container
      button.container_login_form_button(@click='submit') {{ $t("purchase.payment.final.next") }}
</template>

<script>
export default {
  name: 'FinalPayment',
  props: ['typePayment', 'payment'],
  methods: {
    submit() {
      this.$emit('exit')
      this.$router.push({ name: 'Home' })
    },
  },
}
</script>