<template lang='pug'>
div
  Index
  Alert
</template>

<script>
import Index from './views/Index'
import Alert from './components/Alert'
import { mapActions, mapState } from 'vuex'

import { mixin as VueTimers } from 'vue-timers'

export default {
  name: 'App',
  components: {
    Index,
    Alert,
  },
  mixins: [VueTimers],
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapActions('user', ['logOut']),
    ...mapActions('app', ['loadData']),
    async validateSession() {
      if (this.user) {
        console.log('Validating session')
        try {
          await this.user.fetch()
        } catch (error) {
          this.$eventBus.$emit('alert', {
            type: 'warning',
            message: 'Tu sesión se ha cerrado debido a que tu cuenta ha sido abierta en otro dispositivo o la sesión expiró'
          })
          this.$router.push('/')
          await this.logOut()

          this.loadData()
        }
      }
    }
  },
  mounted() {
    this.loadData()
  },
  timers: {
    validateSession: {
      name: 'session',
      time: 1000 * 60 * 3,
      autostart: true,
      repeat: true,
      immediate: true,
    }
  }
}
</script>

<style lang='scss'>
@import './styles/main.scss';
</style>
