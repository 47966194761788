<template lang='pug'>
.container_login
  .container_login_title {{ $t("menu.signup.title") }}

  .container_login_link
    .container_login_link_text {{ $t("menu.signup.fields") }}

  validation-observer.container_login_form(v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit)')
      validation-provider.container_login_form_container_provider(rules='required|alpha_spaces', name='nombre', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='username', :placeholder='$t("menu.signup.input.user")')
          img(src='~@/assets/images/user.svg', alt='logo')

      validation-provider.container_login_form_container_provider(rules='required|email', name='correo electrónico', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='mail', :placeholder='$t("menu.signup.input.mail")')
          img(src='~@/assets/images/mail.svg', alt='logo')

      validation-provider.container_login_form_container_provider(rules='required|numeric|length:10', name='telefono', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='phone', :placeholder='$t("menu.signup.input.phone")')
          img(src='~@/assets/images/phone.svg', alt='logo')

      validation-provider.container_login_form_container_provider(rules='required', name='contraseña', v-slot='{valid, dirty}', ref='contraseña')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(type='password', v-model='password', :placeholder='$t("menu.signup.input.password")')
          img(src='~@/assets/images/password.svg', alt='logo')

      validation-provider.container_login_form_container_provider(rules='required|confirmed:contraseña', name='Confirmar contraseña', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(type='password', :class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(type='password', v-model='password_confirm', :placeholder='$t("menu.signup.input.password_confirm")')
          img(src='~@/assets/images/password.svg', alt='logo')
          img(src='~@/assets/images/password.svg', alt='logo')

      .container_login_form_container_text {{ $t("menu.signup.input.user_title") }}

      validation-provider.container_login_form_container_provider(rules='required', name='Tipo de usuario', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          select.container_login_form_container_provider_field_select(v-model='role')
            option(disabled, selected) {{ $t("menu.signup.input.school_select") }}
            option Maestro
            option Alumno

      .container_login_form_container_text(v-if='role === "Maestro"') {{ $t("menu.signup.input.level_title") }}

      validation-provider.container_login_form_container_provider(rules='required', name='Nivel escolar', v-slot='{valid, dirty}', v-if='role === "Maestro"')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          select.container_login_form_container_provider_field_select(v-model='grade')
            option(disabled, selected) {{ $t("menu.signup.input.school_select") }}
            option(v-for='category in finalCategories', :class='{ active: category.category == currentCategory }', @click='setCurrentCategory(category.category)') {{ category.title }}

      .container_login_form_container_text(v-if='role === "Alumno"') {{ $t("menu.signup.input.school_title") }}

      validation-provider.container_login_form_container_provider(rules='required', name='Escula', v-slot='{valid, dirty}', v-if='role === "Alumno"')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          select.container_login_form_container_provider_field_select(v-model='school')
            option(disabled, selected) {{ $t("menu.signup.input.school_select") }}
            option(v-for='institute in institutes', :key='institute.id') {{ institute.name }}

      .container_login_form_container_text.terms {{ $t("menu.signup.terms.text") }} #[a.container_login_form_container_text_link(href='/aviso-de-privacidad', target='_blank') {{ $t("menu.signup.terms.link") }}]

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button(:disabled='isLoading') {{ isLoading ? $t("menu.signup.input.loading") : $t("menu.signup.input.next") }}

  .container_login_link
    .container_login_link_text {{ $t("menu.signup.account") }} #[a.container_login_link_bold(@click='logIn') {{ $t("menu.signup.registry") }}]
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'form-signup',
  props: ['isLoading'],
  data() {
    return {
      username: null,
      mail: null,
      phone: null,
      password: null,
      password_confirm: null,
      role: null,
      grade: null,
      school: null,
    }
  },
  computed: {
    ...mapState('app', ['categories', 'currentCategory', 'institutes']),
    finalCategories() {
      let categories = []

      this.categories
        .filter((category) => category.name != 'Docentes')
        .forEach((category) => {
          categories.push({
            title: category.name,
            category,
          })
        })

      return categories
    },
  },
  methods: {
    submit() {
      this.$emit('singUp', this.$data)
    },
    logIn() {
      this.$emit('logIn')
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.username = 'Luis Galindo'
      this.mail = 'luis@wearetorque.com'
      this.phone = '0123456789'
      this.password = '1234'
      this.password_confirm = '1234'
      this.role = 'Maestro'
      this.grade = 'Bachillerato'
      this.school = 'Test'
    }
  },
  watch: {
    mail(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.mail = newValue.toLowerCase()
      }
    },
  },
}
</script>