<template lang='pug'>
.container_home
  .container_home_carousel(v-if='!this.search')
    Carousel
  .container_home_list
    List
</template>

<script>
import Carousel from '@/components/Carousel'
import List from '@/components/List'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Carousel,
    List,
  },
  computed: {
    ...mapState('app', ['search']),
  },
}
</script>
