<template lang='pug'>
.container_store
  .container_store_book(v-if='book')
    img.container_store_book_image(:src='book.cover')

    .container_store_book_info
      .container_store_book_info_title {{ book.title }}
      .container_store_book_info_author {{ book.author }}
      .container_store_book_info_description {{ book.description }}

      div(v-if='!isLoading')
        .container_store_book_info_button(v-if='!bought', @click='purchase("digital")')
          .container_store_book_info_button_type {{ $t("store.purchase.a") }}
          .container_store_book_info_button_price $ {{ book.digitalPrice }}

        .container_store_book_info_button(@click='purchase("physical")')
          .container_store_book_info_button_type {{ $t("store.purchase.b") }}
          .container_store_book_info_button_price $ {{ book.physicalPrice }} {{ $t("store.send") }}

        .container_store_book_info_button(v-if='!bought', @click='purchase("both")')
          .container_store_book_info_button_type {{ $t("store.purchase.c") }}
          .container_store_book_info_button_price $ {{ book.digitalPhysicalPrice }} {{ $t("store.send") }}

      .container_store_book_load(v-else)
        .loading

  .container_store_list(v-if='book')
    List(:booksStore='category.books', @reload='boughtBooks')

  .container_store_loading(v-else)
    .loading

  Sidebar(v-if='sidebarShow', @stateSidebar='stateSidebar', @purchase='loginPurchase', :sidebarShow='sidebarShow', :purchaseOption='purchaseOption')

  Purchase(v-if='purchaseShow', @statePurchase='statePurchase', :purchaseOption='purchaseOption', :purchaseShow='purchaseShow')
</template>

<script>
import List from '@/components/List'
import Sidebar from '@/components/Sidebar'
import Purchase from '@/components/Purchase'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Store',
  components: {
    List,
    Sidebar,
    Purchase,
  },
  data() {
    return {
      sidebarShow: false,
      purchaseShow: false,
      purchaseOption: null,
      bought: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('user', ['user', 'userBooks']),
    ...mapGetters('app', ['categoryById', 'bookById']),
    category() {
      return this.book ? this.categoryById(this.book.category.id) : null
    },
    book() {
      return this.bookById(this.$route.params.bookId)
    },
  },
  methods: {
    ...mapActions('user', ['fetchUserBooks']),
    stateSidebar() {
      this.sidebarShow = !this.sidebarShow
    },
    statePurchase() {
      this.purchaseShow = !this.purchaseShow
    },
    loginPurchase() {
      this.sidebarShow = false
      this.purchaseShow = true
    },
    purchase(option) {
      this.purchaseOption = { option, book: this.book }

      if (this.user) {
        this.purchaseShow = true
      } else {
        this.sidebarShow = true
      }
    },
    boughtBooks() {
      let response = Object.values(this.userBooks).find((book) => book.id === this.$route.params.bookId)

      if (response) {
        this.bought = true
      } else {
        this.bought = false
      }

      this.isLoading = false
    },
  },
  async mounted() {
    if (this.user) {
      this.isLoading = true
      await this.fetchUserBooks()
      this.boughtBooks()
    } else {
      this.bought = false
    }
  },
  watch: {
    async user() {
      if (this.user) {
        await this.fetchUserBooks()
        this.boughtBooks()
      } else {
        this.bought = false
      }
      this.isLoading = false
    },

    bought() {
      this.isLoading = false
    },
  },
}
</script>
