import Parse from 'parse'

export default class Institute extends Parse.Object {
  constructor() {
    super('Institute')
  }

  get name() {
    return this.get('name')
  }

  static findInstitutes() {
    return new Parse.Query(this).find()
  }
}

Parse.Object.registerSubclass('Institute', Institute)
