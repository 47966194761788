var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container_login"},[_c('div',{staticClass:"container_login_title"},[_vm._v(_vm._s(_vm.$t("menu.signup.title")))]),_c('div',{staticClass:"container_login_link"},[_c('div',{staticClass:"container_login_link_text"},[_vm._v(_vm._s(_vm.$t("menu.signup.fields")))])]),_c('validation-observer',{staticClass:"container_login_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('form',{staticClass:"container_login_form_container",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required|alpha_spaces","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"placeholder":_vm.$t("menu.signup.input.user")},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/user.svg"),"alt":"logo"}})])]}}],null,true)}),_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required|email","name":"correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail),expression:"mail"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"placeholder":_vm.$t("menu.signup.input.mail")},domProps:{"value":(_vm.mail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mail=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/mail.svg"),"alt":"logo"}})])]}}],null,true)}),_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required|numeric|length:10","name":"telefono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"placeholder":_vm.$t("menu.signup.input.phone")},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/phone.svg"),"alt":"logo"}})])]}}],null,true)}),_c('validation-provider',{ref:"contraseña",staticClass:"container_login_form_container_provider",attrs:{"rules":"required","name":"contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"type":"password","placeholder":_vm.$t("menu.signup.input.password")},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/password.svg"),"alt":"logo"}})])]}}],null,true)}),_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required|confirmed:contraseña","name":"Confirmar contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid },attrs:{"type":"password"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirm),expression:"password_confirm"}],staticClass:"container_login_form_container_provider_field_input",attrs:{"type":"password","placeholder":_vm.$t("menu.signup.input.password_confirm")},domProps:{"value":(_vm.password_confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirm=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/images/password.svg"),"alt":"logo"}}),_c('img',{attrs:{"src":require("@/assets/images/password.svg"),"alt":"logo"}})])]}}],null,true)}),_c('div',{staticClass:"container_login_form_container_text"},[_vm._v(_vm._s(_vm.$t("menu.signup.input.user_title")))]),_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required","name":"Tipo de usuario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.role),expression:"role"}],staticClass:"container_login_form_container_provider_field_select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.role=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t("menu.signup.input.school_select")))]),_c('option',[_vm._v("Maestro")]),_c('option',[_vm._v("Alumno")])])])]}}],null,true)}),(_vm.role === "Maestro")?_c('div',{staticClass:"container_login_form_container_text"},[_vm._v(_vm._s(_vm.$t("menu.signup.input.level_title")))]):_vm._e(),(_vm.role === "Maestro")?_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required","name":"Nivel escolar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.grade),expression:"grade"}],staticClass:"container_login_form_container_provider_field_select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.grade=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t("menu.signup.input.school_select")))]),_vm._l((_vm.finalCategories),function(category){return _c('option',{class:{ active: category.category == _vm.currentCategory },on:{"click":function($event){return _vm.setCurrentCategory(category.category)}}},[_vm._v(_vm._s(category.title))])})],2)])]}}],null,true)}):_vm._e(),(_vm.role === "Alumno")?_c('div',{staticClass:"container_login_form_container_text"},[_vm._v(_vm._s(_vm.$t("menu.signup.input.school_title")))]):_vm._e(),(_vm.role === "Alumno")?_c('validation-provider',{staticClass:"container_login_form_container_provider",attrs:{"rules":"required","name":"Escula"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"container_login_form_container_provider_field",class:{ invalid: dirty && !valid }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.school),expression:"school"}],staticClass:"container_login_form_container_provider_field_select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.school=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t("menu.signup.input.school_select")))]),_vm._l((_vm.institutes),function(institute){return _c('option',{key:institute.id},[_vm._v(_vm._s(institute.name))])})],2)])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"container_login_form_container_text terms"},[_vm._v(_vm._s(_vm.$t("menu.signup.terms.text"))+" "),_c('a',{staticClass:"container_login_form_container_text_link",attrs:{"href":"/aviso-de-privacidad","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("menu.signup.terms.link")))])]),_c('div',{staticClass:"container_login_form_errors"},_vm._l((_vm.parseErrors(errors)),function(error){return (_vm.parseErrors(errors))?_c('div',{staticClass:"container_login_form_errors_text"},[_vm._v(_vm._s(error))]):_vm._e()}),0),_c('button',{staticClass:"container_login_form_button",attrs:{"disabled":_vm.isLoading}},[_vm._v(_vm._s(_vm.isLoading ? _vm.$t("menu.signup.input.loading") : _vm.$t("menu.signup.input.next")))])],1)]}}])}),_c('div',{staticClass:"container_login_link"},[_c('div',{staticClass:"container_login_link_text"},[_vm._v(_vm._s(_vm.$t("menu.signup.account"))+" "),_c('a',{staticClass:"container_login_link_bold",on:{"click":_vm.logIn}},[_vm._v(_vm._s(_vm.$t("menu.signup.registry")))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }