<template lang='pug'>
.container_login
  .container_login_title.purchase_title {{ $t("purchase.direction.title") }}

  validation-observer.container_login_form(v-slot='{handleSubmit, errors}')
    form.container_login_form_container(@submit.prevent='handleSubmit(submit)')
      validation-provider.container_login_form_container_provider(rules='required', name='calle y numero exterior', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='street', :placeholder='$t("purchase.direction.inputs.a")')

      validation-provider.container_login_form_container_provider(rules='required', name='numero interior', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='internalNumber', :placeholder='$t("purchase.direction.inputs.b")')

      validation-provider.container_login_form_container_provider(rules='required', name='colonia', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='suburb', :placeholder='$t("purchase.direction.inputs.c")')

      validation-provider.container_login_form_container_provider(rules='required', name='delegacion / municipio', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='town', :placeholder='$t("purchase.direction.inputs.d")')

      validation-provider.container_login_form_container_provider(rules='required', name='codigo postal', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='postalCode', :placeholder='$t("purchase.direction.inputs.e")')

      validation-provider.container_login_form_container_provider(rules='alpha_spaces', name='referencias adicionales', v-slot='{valid, dirty}')
        .container_login_form_container_provider_field(:class='{ invalid: dirty && !valid }')
          input.container_login_form_container_provider_field_input(v-model='references', :placeholder='$t("purchase.direction.inputs.f")')

      .container_login_form_errors
        .container_login_form_errors_text(v-if='parseErrors(errors)', v-for='error in parseErrors(errors)') {{ error }}

      button.container_login_form_button.purchase_direction_button(:disable='isLoading') {{ $t("purchase.next") }}
</template>

<script>
export default {
  name: 'Direction',
  props: ['data', 'isLoading'],
  data() {
    return {
      street: null,
      internalNumber: null,
      suburb: null,
      town: null,
      postalCode: null,
      references: null,
    }
  },
  methods: {
    submit() {
      this.$emit('direction', this.$data)
    },
    parseErrors(errors) {
      return Object.values(errors)
        .map((element) => element[0])
        .filter((error) => error)
    },
  },
  mounted() {
    if (process.env.NODE_ENV != 'production') {
      this.street = 'tabasco 311'
      this.internalNumber = '401'
      this.suburb = 'Roma Norte'
      this.town = 'Cuauhtemoc'
      this.postalCode = '06700'
      this.references = 'Edificio color blanco'
    }

    if (this.data) {
      this.street = this.data.street
      this.internalNumber = this.data.internalNumber
      this.suburb = this.data.suburb
      this.town = this.data.town
      this.postalCode = this.data.postalCode
      this.references = this.data.references
    }
  },
}
</script>