<template lang='pug'>
.container_global(v-if='currentRoute() !== "pdf" && currentRoute() !== "recover"')
  HomeSide(v-if='currentRoute() === "home"', @stateSidebar='stateSidebar')

  StoreSide(v-if='currentRoute() === "store"', @stateSidebar='stateSidebar')

  LibrarySide(v-if='currentRoute() === "library"', @stateSidebar='stateSidebar')

  ProfileSide(v-if='currentRoute() === "profile"', @stateSidebar='stateSidebar')

  PrivacySide(v-if='currentRoute() === "privacy"', @stateSidebar='stateSidebar')

  .content
    .content_header_home(v-if='currentRoute() === "home"')
      .content_header_search
        img(src='~@/assets/images/search.svg', alt='search')
        input(type='text', :placeholder='$t("content.search.input")', v-model='searchInput', @change='searchBook')
        button(@click='searchBook') {{ $t("content.search.button") }}

      .content_header_login(v-if='user')
        button(@click='stateSidebar')
          h1 {{ $t("content.login.menu") }}
          img(src='~@/assets/images/menu.svg', alt='menu')

      .content_header_login(v-else)
        button(@click='stateSidebar')
          h1 {{ $t("content.login.login") }}
          img(src='~@/assets/images/login.svg', alt='login')

    .content_header(v-else)
      .content_header_login(v-if='user')
        button(@click='stateSidebar')
          h1 {{ $t("content.login.menu") }}
          img(src='~@/assets/images/menu.svg', alt='menu')

      .content_header_login(v-else)
        button(@click='stateSidebar')
          h1 {{ $t("content.login.login") }}
          img(src='~@/assets/images/login.svg', alt='login')

    router-view

  Sidebar(v-if='sidebarShow', @stateSidebar='stateSidebar', :sidebarShow='sidebarShow')

router-view(v-else)
</template>

<script>
import HomeSide from '@/components/sidenav/HomeSide'
import StoreSide from '@/components/sidenav/StoreSide'
import LibrarySide from '@/components/sidenav/LibrarySide'
import ProfileSide from '@/components/sidenav/ProfileSide'
import PrivacySide from '@/components/sidenav/PrivacySide'
import Sidebar from '@/components/Sidebar'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    HomeSide,
    StoreSide,
    LibrarySide,
    ProfileSide,
    PrivacySide,
    Sidebar,
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    ...mapMutations('app', ['setSearch']),
    currentRoute() {
      let path = this.$router.currentRoute.fullPath

      if (path.includes('/mis-libros')) {
        return 'library'
      } else if (path.includes('/perfil')) {
        return 'profile'
      } else if (path.includes('/tienda')) {
        return 'store'
      } else if (path.includes('/pdf')) {
        return 'pdf'
      } else if (path.includes('/recuperar-cuenta') || path.includes('/token-invalido')) {
        return 'recover'
      } else if (path.includes('/aviso-de-privacidad')) {
        return 'privacy'
      } else {
        return 'home'
      }
    },
    stateSidebar() {
      this.sidebarShow = !this.sidebarShow
    },
    searchBook() {
      this.setSearch(this.searchInput)
    },
  },
  data() {
    return {
      sidebarShow: false,
      searchInput: null,
    }
  },
}
</script>
