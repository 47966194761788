<template lang="pug">
.sidebar
  .sidebar_link
    router-link(:to={name: 'Home'})
      img(src="~@/assets/images/logo.svg", alt="logo")

  .sidebar_logo
    router-link(:to={name: 'Home'})
      //- img(src="~@/assets/images/logo.svg", alt="logo")
    button(@click='stateSidebar')
      img(src="~@/assets/images/menu.svg", alt="menu")

  .sidebar_title {{ $t('sidebar.home') }}

  .sidebar_search
    img(src="~@/assets/images/search.svg", alt="search")
    input(type='text', :placeholder="$t('content.search.input')", v-model='searchInput' @change='searchBook')
    button(@click='searchBook') {{ $t('content.search.button') }}

  .sidebar_browser
    button(v-for="category in finalCategories" :class="{active: category.category == currentCategory}" @click="setCurrentCategory(category.category)") {{category.title}}

  .sidebar_bird_home
    img(src="~@/assets/images/bird.svg", alt="bird")

  .sidebar_terms_home {{ $t('sidebar.terms') }}
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'HomeSide',
  computed: {
    ...mapState('app', ['categories', 'currentCategory']),
    finalCategories() {
      let categories = [
        {
          title: 'Ver todo',
          category: null,
        },
      ]

      this.categories
        .filter((category) => category.name != 'Docentes')
        .forEach((category) => {
          categories.push({
            title: category.name,
            category,
          })
        })

      return categories
    },
  },
  methods: {
    ...mapMutations('app', ['setCurrentCategory', 'setSearch']),
    stateSidebar() {
      this.$emit('stateSidebar')
    },
    searchBook() {
      this.setSearch(this.searchInput)
    },
  },
  data() {
    return {
      searchInput: null,
    }
  },
}
</script>