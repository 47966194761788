import User from '@/parse/models/User'
import Parse from '@/parse'

let state = {
  user: User.current(),
  userBooks: [],
  userCards: [],
  userCategories: [],
  userCurrentCategory: null
}

let getters = {
  isAuthenticated(state) {
    return Boolean(state.user)
  }
}

let actions = {
  async signUp(store, params) {
    let user = new User()
    let role = await User.findRole(params.role)

    user.setUsername(params.username)
    user.setPassword(params.password)
    user.setEmail(params.username)
    user.set('name', params.name)
    user.set('phone', `+52${params.phone}`)
    user.set('grade', params.grade)
    user.set('institute', params.institute)
    user.set('role', role[0])
    user.set('schoolCode', params.schoolCode)

    await user.signUp()
    store.commit('setUser', user)

    return user
  },
  async setName(store, user) {
    try {
      let userUpdated = await User.setName(user)
      store.commit('setUser', userUpdated)
      return { status: true, response: userUpdated }
    } catch (error) {
      return { status: false, response: error }
    }
  },
  async logIn(store, { username, password }) {
    let user = await User.logIn(username, password, {
      device_id: 'test'
    })
    user
    store.commit('setUser', user)
  },
  async logOut({ commit }) {
    await User.logOut()
    commit('setUser', null)
    commit('deleteData')
  },
  async fetchUserBooks({ commit, dispatch }) {
    let response = await Parse.Cloud.run('getBooks')
    response.sort((a, b) => (a.title > b.title ? 1 : -1))
    commit('setBooks', response)
    dispatch('fetchCategories')
  },
  async fetchUserCards({ state, commit }) {
    let response = await state.user.getCards()
    commit('setCards', response)
  },
  async deleteUserCard({ commit }, cardId) {
    try {
      await Parse.Cloud.run('manageUserCards', {
        event: 'delete',
        card: cardId
      })

      let response = await User.getCards()
      commit('setCards', response)

      return { status: true, response }
    } catch (error) {
      return { status: false, response: error }
    }
  },
  async redeemSchoolCode({ dispatch }, schoolCode) {
    let response = await Parse.Cloud.run('matchGrupalKey', {
      code: schoolCode
    })

    dispatch('fetchUserBooks')

    return response
  },
  fetchCategories({ state, commit }) {
    let listCategory = state.userBooks
      .map(book => book.category.name)
      .filter((value, index, self) => {
        return self.indexOf(value) === index
      })

    commit('setCategories', listCategory)
  }
}

let mutations = {
  setUser(state, user) {
    state.user = user
  },
  setBooks(state, books) {
    state.userBooks = books
  },
  setCards(state, cards) {
    state.userCards = cards
  },
  deleteData(state) {
    state.userBooks = []
    state.userCards = []
  },
  setCategories(state, list) {
    state.userCategories = list
  },
  setCurrentCategory(state, category) {
    state.userCurrentCategory = category
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
